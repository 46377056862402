import { FormStateProvider, useFormState } from '@bbc-account/id-formaxe';
import React, { useCallback, useMemo, useState } from 'react';
import { PageBeforeUnloadEventProvider } from '../events';
import { EmailFirstRoutes } from './routes';
import { useEmailFirstFormPageBeforeUnload } from './useEmailFirstFormPageBeforeUnload';

export const EmailFirstFormPage = () => {
    const [
        beforeUnloadEventListenersEnabled,
        setBeforeUnloadEventListenersEnabled,
    ] = useState();

    const initialErrors = useMemo(() => ({}), []);

    const initialValues = useMemo(() => ({}), []);

    const formState = useFormState({
        initialErrors,
        initialValues,
    });

    const handleBeforeUnloadEventListenersChange = useCallback(
        newBeforeUnloadEventListenersEnabled => {
            setBeforeUnloadEventListenersEnabled(
                newBeforeUnloadEventListenersEnabled
            );
        },
        []
    );

    useEmailFirstFormPageBeforeUnload({
        eventListenersEnabled: beforeUnloadEventListenersEnabled,
        fieldValues: formState.fieldValues,
    });

    return (
        <PageBeforeUnloadEventProvider
            initBeforeUnloadEventListenersEnabled
            onBeforeUnloadEventListenersChange={
                handleBeforeUnloadEventListenersChange
            }
        >
            <FormStateProvider value={formState}>
                <EmailFirstRoutes />
            </FormStateProvider>
        </PageBeforeUnloadEventProvider>
    );
};
