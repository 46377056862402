import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import usePreventFormResubmission from '../../../hooks/usePreventFormResubmission';
import Text from '../../shared/text';
import { useStateContext } from '../../../modules/stateContext';
import MagicLinkCta from '../../shared/magicLinkCta';

export const MagicLinkCopy = ({ email, onSubmit }) => {
    const userContext = useStateContext();
    usePreventFormResubmission();

    const {
        location: { isUk },
        signIn,
        featureToggles: { checkHasPassword },
    } = userContext;

    const hasPassword = signIn?.context?.hasPassword;
    const username = signIn?.data?.username;

    const headingTitle = useMemo(() => {
        if (isUk || checkHasPassword === false || hasPassword === true) {
            return 'auth.magicLink.cta.hasPassword.title';
        }

        return 'auth.magicLink.cta.noPassword.title';
    }, [checkHasPassword, isUk, hasPassword]);

    return (
        <div className="u-margin-top-quad u-margin-bottom-penta">
            <Heading fontScale="indexHeadlineMedium" level={2}>
                <FormattedMessage id={headingTitle} />
            </Heading>
            <Text className="u-margin-top">
                <FormattedMessage id="auth.magicLink.cta.text" />
            </Text>
            <MagicLinkCta email={email || username} onSubmit={onSubmit} />
        </div>
    );
};

MagicLinkCopy.propTypes = {
    email: PropTypes.string,
    onSubmit: PropTypes.func,
};
