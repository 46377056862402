export const AUTH_BASE_PATH = '/auth/v2';
export const AUTH_CHECK_EMAIL_PATH = `${AUTH_BASE_PATH}/check/email`;
export const AUTH_SIGN_IN_PATH = `${AUTH_BASE_PATH}/signin`;
export const AUTH_SIGN_IN_PATH_FEDERATED = `${AUTH_BASE_PATH}/federated/signin`;
export const AUTH_REGISTER = `${AUTH_BASE_PATH}/register`;
export const AUTH_REGISTER_FEDERATED = `${AUTH_BASE_PATH}/federated/register`;
export const AUTH_REGISTERV2 = `/auth/register`;
export const AUTH_REGISTERV2_FEDERATED = `/auth/register/federated`;
export const AUTH_GENERATE_OTP_PATH = `${AUTH_BASE_PATH}/otp/generate`;
export const AUTH_VALIDATE_OTP_PATH = `${AUTH_BASE_PATH}/otp/verify`;
