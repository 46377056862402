import { CHILD_PERMISSIONS_URL } from './endpoints/usingTheBbc';
import {
    SIGN_IN_PATH,
    SIGN_IN_FEDERATED_PATH,
    AUTH_PATH,
    FORGOTTEN_PASSWORD_PATH,
} from './endpoints/account';

function buildSignInLink(href: string) {
    return {
        id: 'validation.signin.link',
        attributes: { href },
    };
}

function buildDuplicateLink(signInUrl: string, resetUrl: string) {
    return {
        id: 'emailFirst.email.duplicate',
        attributes: [{ href: signInUrl }, { href: resetUrl }],
    };
}

const duplicateLink = buildDuplicateLink(AUTH_PATH, FORGOTTEN_PASSWORD_PATH);

const duplicateLinkFederated = buildDuplicateLink(
    SIGN_IN_FEDERATED_PATH,
    FORGOTTEN_PASSWORD_PATH
);

const signInAuthLink = buildSignInLink(AUTH_PATH);

const signInLink = buildSignInLink(SIGN_IN_PATH);

const signInFederatedLink = buildSignInLink(SIGN_IN_FEDERATED_PATH);

const findOutMoreAboutChildPermissionsLink = {
    id: 'permissions.link.findOutMore',
    attributes: {
        href: CHILD_PERMISSIONS_URL,
        external: true,
    },
};

// FIXME: remove inconsistency with duplicate messages punctuation
export const duplicateMessages = {
    email: ['validation.email.duplicate', ' ', signInAuthLink],
    emailDeprecated: ['validation.email.duplicate', ' ', signInLink],
    federatedEmail: ['validation.email.duplicate', ' ', signInFederatedLink],
    parentLedUsername: 'validation.username.duplicate',
    username: ['validation.username.duplicate', '. ', signInAuthLink],
    usernameDeprecated: ['validation.username.duplicate', '. ', signInLink],
};

export const duplicateV2Messages = {
    email: duplicateLink,
    federatedEmail: duplicateLinkFederated,
};

export const policyMessages = {
    childEmailNotVerified: 'validation.email.notVerified',
    childAccountAlreadyLinked: 'validation.alreadyLinked',
    emailAlreadyVerified: 'validation.email.alreadyVerified',
    guardianAccountDoesNotExist: 'validation.guardianAccount.doesNotExist',
    guardianAccountIsUnderAge: 'validation.guardianAccount.underAge',
    guardianAccountOutstandingRequest:
        'validation.guardianAccount.waitingToRespond',
    guardianAccountIsInvalid: 'validation.guardianAccount.invalid',
    resendLimitReached: 'validation.reachedLimit',
    valueNotAcceptable: 'validation.invalidValue',
    unknownError: 'validation.unknownError',
};

export const sendAnonymousPermissionsEmailMessages = {
    childAccountAlreadyLinked: 'validation.permissions.alreadyLinked',
    childNotFound: [
        'validation.username.notFound',
        ' ',
        'validation.username.askChild',
    ],
    requestLimitExceeded: [
        'validation.maxSubmissions.accountLimitReached',
        ' ',
        'validation.maxSubmissions.checkOrTryAgain',
    ],
    guardianIsUnderAge: 'validation.email.invalidDetails',
    guardianEmailNotAssociatedWithChildAccount: [
        'validation.email.notRelated',
        ' ',
        'validation.email.checkCorrect',
    ],
    userIsOverAgeForPermissions: [
        'validation.ageBoundary.childTooOld',
        ' ',
        findOutMoreAboutChildPermissionsLink,
    ],
};

export const displayNameMessages = {
    profanity: 'validation.displayName.profanity',
};

export const gracefulDegradationMessage = 'validation.generic.error';

export const invalidEmailMessage = [
    'validation.generic.notRight',
    '. ',
    'validation.email.invalidEmailFormat',
];
