import { FlexContainer, Heading } from '@bbc-account/id-components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useExperiment } from '../../../experiments/useExperiment';
import { useStateContext } from '../../../modules/stateContext';
import ExperimentAgeGateCopy from '../../register/experiments/ageGateCopyUx/experimentAgeGateCopy';
import experimentVariantComponentMap from '../../register/experiments/ageGateCopyUx/experimentVariantComponentMap';
import ApplicationLinkContainer from '../applicationLink/applicationLinkContainer';
import Explainer from '../explainer';
import Text from '../text';

const AgeSelect = ({ underAgeBoundary, overAgeBoundary }) => {
    const userContext = useStateContext();

    const {
        featureToggles: { regAgeGateCopyUxExperiment },
    } = userContext;

    const ageGateCopyExperimentVariant = useExperiment('accwb_agegate_copy');

    const experimentVariantSupported = !!experimentVariantComponentMap[
        ageGateCopyExperimentVariant
    ];

    const shouldShowExperiment =
        regAgeGateCopyUxExperiment && experimentVariantSupported;

    return (
        <div className="buttons buttons--inline">
            {shouldShowExperiment ? (
                <div data-testid="age-gate-copy-experiment">
                    <ExperimentAgeGateCopy
                        variant={ageGateCopyExperimentVariant}
                    />
                    <Text>This account is for someone who is...</Text>
                </div>
            ) : (
                <div className="u-padding-bottom-quad u-padding-top-quad">
                    <Heading
                        level={2}
                        fontScale="sectionHeading"
                        fontWeight="regular"
                    >
                        <FormattedMessage id="register.title.ageCheck" />
                    </Heading>
                </div>
            )}
            <FlexContainer>
                <ApplicationLinkContainer
                    button
                    fullWidth
                    primary
                    external
                    withExternalHash={false}
                    href={underAgeBoundary.href}
                >
                    <FormattedMessage {...underAgeBoundary.translationKey} />
                </ApplicationLinkContainer>
                <ApplicationLinkContainer
                    button
                    primary
                    fullWidth
                    external
                    withExternalHash={false}
                    href={overAgeBoundary.href}
                >
                    <FormattedMessage {...overAgeBoundary.translationKey} />
                </ApplicationLinkContainer>
            </FlexContainer>
            <Explainer
                cta={<FormattedMessage id="common.explainer.link.purpose" />}
                explainers={
                    <FormattedMessage id="common.explainer.body.ageCheck" />
                }
                name="ageCheck"
            />
        </div>
    );
};

AgeSelect.displayName = 'AgeSelect';

const ageBoundaryPropType = PropTypes.shape({
    translationKey: PropTypes.shape({
        id: PropTypes.string.isRequired,
        values: PropTypes.string,
    }),
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
});

AgeSelect.propTypes = {
    underAgeBoundary: ageBoundaryPropType.isRequired,
    overAgeBoundary: ageBoundaryPropType.isRequired,
};

export default AgeSelect;
