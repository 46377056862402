type FetchParameters = Parameters<typeof fetch>;

type FetchInput = FetchParameters[0];

type FetchInit = Exclude<FetchParameters[1], undefined>;

type MakeRequestOptions<RequestBody extends object> = Omit<
    FetchInit,
    'body' | 'credentials'
> & {
    body?: RequestBody;
};

export async function makeRequest<
    RequestBody extends object,
    ResponseBody = unknown
>(url: FetchInput, options: MakeRequestOptions<RequestBody> = {}) {
    const { body, headers = {} } = options;

    const mergedOptions: FetchInit = {
        ...options,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        body: typeof body === 'undefined' ? undefined : JSON.stringify(body),
        credentials: 'include',
    };

    const response = await fetch(url, mergedOptions);

    const responseJson = (await response.json()) as ResponseBody;

    return responseJson;
}
