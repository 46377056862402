import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../pageLayout/layout';
import SignInContainer from './signInContainer';
import RegisterCta from './registerCta';
import { SIGN_IN_FEDERATED_PATH } from '../../../shared/endpoints/account';
import CassoText from '../shared/cassoText';
import { useStateContext } from '../../modules/stateContext';

const FederatedSignInLayout = () => {
    const userContext = useStateContext();

    const suppressRegisterLinks = userContext.suppressRegisterLinks === 'true';

    return (
        <Layout
            showServiceIdentifiers
            pageId="federated-signin-page"
            heading={<FormattedMessage id="signIn.title.introduction" />}
            secondaryContent={
                !suppressRegisterLinks && <RegisterCta isFederated />
            }
        >
            <SignInContainer formAction={SIGN_IN_FEDERATED_PATH} />
            <CassoText additionalClasses="u-padding-top-double u-margin-bottom-none" />
        </Layout>
    );
};

FederatedSignInLayout.displayName = 'FederatedSignInLayout';

export default FederatedSignInLayout;
