import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading, InstructionList } from '@bbc-account/id-components';
import Layout from '../../pageLayout/layout';

const MagicLinkValidateClientSuccess = () => {
    const intl = useIntl();
    const instructions = [
        intl.formatMessage({ id: 'auth.magicLink.deepLink.goBack' }),
        intl.formatMessage({ id: 'auth.magicLink.deepLink.signIn' }),
    ];

    return (
        <Layout
            pageId="signin-page"
            heading={<FormattedMessage id="auth.magicLink.validation.title" />}
            showServiceIdentifiers
        >
            <InstructionList
                items={instructions}
                title={
                    <Heading level={2} fontScale="indexHeadlineSmall">
                        <FormattedMessage id="auth.magicLink.checkEmail.whatNext" />
                    </Heading>
                }
            />
        </Layout>
    );
};

export default MagicLinkValidateClientSuccess;
