import styled from 'styled-components';
import { EmailFirstForm } from '../../emailFirstForm';

export const StyledEmailFirstMarketingSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const StyledEmailFirstForm = styled(EmailFirstForm)`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
