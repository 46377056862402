import { getUserOriginFromPtrt } from './getUserOriginFromPtrt';

type GetAppNameOptions = {
    clientId?: string;
    generateUserOriginFromPtrt?: boolean;
    ptrt?: {
        value?: string;
    };
    userOrigin?: string;
};

export function getAppName({
    clientId,
    generateUserOriginFromPtrt,
    ptrt,
    userOrigin,
}: GetAppNameOptions) {
    if (clientId && clientId !== 'Account') {
        return clientId;
    }

    if (!generateUserOriginFromPtrt) {
        return userOrigin || 'Account';
    }

    return userOrigin || getUserOriginFromPtrt(ptrt?.value);
}
