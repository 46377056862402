import React from 'react';
import Text from '../../../shared/text';

const FunctionalShortVariant = () => (
    <Text>
        We need to know who this account is for. This helps us show the right
        content for their age.
    </Text>
);

export default FunctionalShortVariant;
