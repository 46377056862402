import { Request, Response } from 'express';
import { ParamsDictionary } from 'express-serve-static-core';

type Layout = 'iframe' | string;

type RecordWithLayout = {
    layout?: Layout;
};

type IsRequestIframeLayoutRequestBody = RecordWithLayout;

type IsRequestIframeLayoutRequestQuery = RecordWithLayout;

type IsResponseIframeLayoutResponseLocals = RecordWithLayout;

type IsRequestIframeLayoutRequest = Request<
    ParamsDictionary,
    unknown,
    IsRequestIframeLayoutRequestBody,
    IsRequestIframeLayoutRequestQuery
>;

type IsResponseIframeLayoutResponse = Response<
    unknown,
    IsResponseIframeLayoutResponseLocals
>;

export const isIframeLayout = (layout?: Layout) => layout === 'iframe';

export const isRequestIframeLayout = ({
    body,
    query,
}: IsRequestIframeLayoutRequest) =>
    isIframeLayout(body.layout) || isIframeLayout(query.layout);

export const isResponseIframeLayout = ({
    locals: { layout },
}: IsResponseIframeLayoutResponse) => isIframeLayout(layout);
