export const SINGLE_ENTRY_AUTH_EXPERIMENT = {
    METRICS: {
        O13_USER_SUCCESSFULLY_REGISTERED: 'ex_uk_user_successfully_registered',
        O13_USER_SUCCESSFULLY_SIGNED_IN: 'o13_user_successfully_signin',
    },
};

const isUserBucketedInVariation = (locals, experiment, variationName) => {
    return (
        !!locals.mvt.experiments &&
        locals.mvt.experiments[experiment.NAME] ===
            experiment.VARIATIONS[variationName]
    );
};

export const EMAIL_VERIFICATION_OPTIMIZELY_EXPERIMENT = {
    NAME: 'mandatory_email_verification_rollout_uk',
    METRICS: {
        O13_USER_SUCCESSFULLY_REGISTERED: 'o13_user_successfully_registered',
    },
    VARIATIONS: {
        on: 'on',
        off: 'off',
    },
};

export { isUserBucketedInVariation };
