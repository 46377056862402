import React from 'react';
import { useStateContext } from '../../../modules/stateContext';
import UkMarketingPreferenceForm from '../../marketingPreference/ukMarketingPreferenceForm';
import InternationalMarketingPreferenceForm from '../../marketingPreference/internationalMarketingPreferenceForm';
import { ThankYouWithoutMarketing } from '../../thankYouWithoutMarketing';
import { REGISTER_FEDERATED_THANKS_WITHOUT_MARKETING_PATH } from '../../../../shared/endpoints/account';

const RegisterThanks = () => {
    const {
        isFederated,
        location,
        suppressMarketing,
        featureToggles,
    } = useStateContext();

    if (featureToggles.suppressMarketing && suppressMarketing && isFederated) {
        return (
            <ThankYouWithoutMarketing
                action={REGISTER_FEDERATED_THANKS_WITHOUT_MARKETING_PATH}
                title="registration.thankYou.title"
            />
        );
    }

    if (isFederated && location?.isUk === false) {
        return (
            <InternationalMarketingPreferenceForm
                showEmailVerificationReminder
            />
        );
    }

    if (isFederated || location?.isUk !== false) {
        return <UkMarketingPreferenceForm />;
    }

    return (
        <InternationalMarketingPreferenceForm showEmailVerificationReminder />
    );
};

RegisterThanks.displayName = 'RegisterThanks';

export default RegisterThanks;
