import { readFileSync } from 'fs';
import { join, resolve } from 'path';
import { pickBy } from 'lodash';
import { getContentFromMetaTag } from '../../app/utilities/dom/meta';

const LOCAL_INSECURE_CONFIG = join(
    __dirname,
    '../../../config/localInsecure.dist.json'
);
const LOCAL_SECURE_CONFIG = join(
    __dirname,
    '../../../config/localSecure.dist.json'
);

function readJsonFile(path) {
    try {
        const fileContents = readFileSync(path, 'utf-8');

        return JSON.parse(fileContents);
    } catch (error) {
        return undefined;
    }
}

function mergeServerConfig(config) {
    const secureConfigKeys = Object.keys(readJsonFile(LOCAL_SECURE_CONFIG));
    const insecureConfigKeys = Object.keys(readJsonFile(LOCAL_INSECURE_CONFIG));

    const { environment, release_version: release } = config;

    const secure = pickBy(config.configuration, (_value, key) =>
        secureConfigKeys.includes(key)
    );
    const insecure = pickBy(config.configuration, (_value, key) =>
        insecureConfigKeys.includes(key)
    );

    return {
        secure,
        insecure: {
            ...insecure,
            environment,
            release,
        },
    };
}

function server() {
    const bakeConfig = readJsonFile('/etc/bake-scripts/config.json');

    if (bakeConfig) {
        return mergeServerConfig(bakeConfig);
    }

    const insecure = readJsonFile(
        resolve(__dirname, '../../../config/local.json')
    );

    return {
        insecure,
        secure: {},
    };
}

function client() {
    const config = {
        insecure: JSON.parse(getContentFromMetaTag('config')),
        secure: {},
    };

    return config;
}

export { server, client };
