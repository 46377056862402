import React, { useMemo } from 'react';
import { oneTimePassword } from '@bbc-account/id-validators';
import { EmailFirstTextFormInput } from '../text';

export const EMAIL_FIRST_VERIFICATION_FORM_INPUT_NAME = 'otp';

export const EmailFirstVerificationFormInput = ({ defaultValue }) => {
    const validator = useMemo(
        () => ({
            validate: value => oneTimePassword.validate(value),
        }),
        []
    );

    return (
        <EmailFirstTextFormInput
            defaultValue={defaultValue}
            id={EMAIL_FIRST_VERIFICATION_FORM_INPUT_NAME}
            label="emailFirst.verification.label"
            name={EMAIL_FIRST_VERIFICATION_FORM_INPUT_NAME}
            validator={validator}
        />
    );
};

EmailFirstVerificationFormInput.propTypes = {
    defaultValue: EmailFirstTextFormInput.propTypes.defaultValue,
};
