import React from 'react';
import { Switch } from 'react-router-dom';

import RouteWithLayout from './helpers/routeWithLayout';
import ApplicationWrapper from '../components/applicationWrapper';

import SignInFederated from './signInFederated';
import SignIn from './signIn';
import RegisterFederated from './registerFederated';
import Register from './register';
import Account from './account';
import Permissions from './permissions';
import HelpFederated from './helpFederated';
import TvCode from './tvCode';
import Auth from './auth';
import { EmailFirstFormPage } from '../pages/email-first/emailFirstFormPage';

function getRoutes() {
    return (
        <Switch>
            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/signin/federated"
                component={SignInFederated}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/signin"
                component={SignIn}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/register/federated"
                component={RegisterFederated}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/register"
                component={Register}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/account"
                component={Account}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/permissions"
                component={Permissions}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/help/federated"
                component={HelpFederated}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/tvcode"
                component={TvCode}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/auth/identifier"
                component={EmailFirstFormPage}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/auth/register/federated"
                component={EmailFirstFormPage}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/auth/register"
                component={EmailFirstFormPage}
            />

            <RouteWithLayout
                layout={ApplicationWrapper}
                path="/auth"
                component={Auth}
            />
        </Switch>
    );
}

export default getRoutes;
