import { useCallback } from 'react';
import { invalidEmailMessage } from '../../../../../shared/errorMessages';
import { checkEmailUniqueness } from '../../../../services/checkEmailUniqueness';
import { useStateContext } from '../../../../modules/stateContext';
import { checkUserIdentifierUniqueness } from '../../../../services/checkUserIdentifierUniqueness';

type CheckEmailUniquenessReturnType = Awaited<
    ReturnType<typeof checkEmailUniqueness>
>;

type CheckEmailUniquenessReturnMessage = Exclude<
    CheckEmailUniquenessReturnType['message'],
    undefined
>;

type CheckUniquenessReturnValue = {
    dns?: boolean;
    error?: string | string[] | CheckEmailUniquenessReturnMessage;
    exists?: boolean;
};

async function execCheckUserIdentifierUniqueness(identifier: string) {
    let dns: CheckUniquenessReturnValue['dns'];

    let error: CheckUniquenessReturnValue['error'];

    let exists: CheckUniquenessReturnValue['exists'] = false;

    try {
        ({ dns, exists, error } = await checkUserIdentifierUniqueness(
            identifier
        ));

        if (typeof error !== 'undefined') {
            throw new TypeError(String(error));
        }

        if (dns) {
            error = {
                email: {
                    id: 'emailFirst.email.duplicate',
                    attributes: [
                        {
                            href: '/auth',
                        },
                        {
                            href: '/signin/forgotten',
                        },
                    ],
                },
                federatedEmail: {
                    id: 'emailFirst.email.duplicate',
                    attributes: [
                        {
                            href: '/signin/federated',
                        },
                        {
                            href: '/signin/forgotten',
                        },
                    ],
                },
            };
        } else {
            error = invalidEmailMessage;
        }
    } catch {
        error = invalidEmailMessage;
    }

    return { dns, error, exists };
}

export function useEmailFirstEmailFormCheckUniqueness() {
    const { isSingleEntryAuth } = useStateContext();

    const checkUniqueness = useCallback(
        async (identifier: string): Promise<CheckUniquenessReturnValue> => {
            try {
                if (isSingleEntryAuth) {
                    return execCheckUserIdentifierUniqueness(identifier);
                }

                const { message: error, success } = await checkEmailUniqueness(
                    identifier
                );

                return { dns: undefined, error, exists: !success };
            } catch (error) {
                let errorMessage = 'unknown error';

                if (error instanceof Error) {
                    errorMessage = error.message;
                } else {
                    errorMessage = String(error);
                }

                return {
                    dns: undefined,
                    error: errorMessage,
                    exists: undefined,
                };
            }
        },
        [isSingleEntryAuth]
    );

    return checkUniqueness;
}
