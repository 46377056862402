import { values } from 'lodash';
import languagesWithTranslations from '@bbc-account/id-translations/translations/languages-with-translations.json';
import policies from './policies';
import ageBrackets from './ageBrackets';
import components from './components';

const ab = values(ageBrackets);
const policy = values(policies);
const component = values(components);
const lang = languagesWithTranslations;

export { ab, policy, component, lang };
