import React from 'react';
import { TextInput } from '../textInput/textInput';

export const SelectInput = ({
    'aria-label': ariaLabel,
    'aria-describedby': ariaDescribedBy,
    autoComplete,
    children,
    disabled,
    errorComponent,
    eventHandlers,
    fieldIcon,
    hasShowPasswordToggle,
    id,
    isValid,
    label,
    minimal,
    name,
    options,
    placeholder,
    value,
}) => {
    return (
        <TextInput
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedBy}
            as="select"
            autoComplete={autoComplete}
            disabled={disabled}
            errorComponent={errorComponent}
            eventHandlers={eventHandlers}
            fieldIcon={fieldIcon}
            hasShowPasswordToggle={hasShowPasswordToggle}
            id={id}
            isValid={isValid}
            label={label}
            minimal={minimal}
            name={name}
            options={options}
            placeholder={placeholder}
            value={value}
        >
            {children}
        </TextInput>
    );
};

SelectInput.propTypes = {
    'aria-label': TextInput.propTypes['aria-label'],
    'aria-describedby': TextInput.propTypes['aria-describedby'],
    autoComplete: TextInput.propTypes.autoComplete,
    children: TextInput.propTypes.children,
    disabled: TextInput.propTypes.disabled,
    errorComponent: TextInput.propTypes.errorComponent,
    eventHandlers: TextInput.propTypes.eventHandlers,
    fieldIcon: TextInput.propTypes.fieldIcon,
    hasShowPasswordToggle: TextInput.propTypes.hasShowPasswordToggle,
    id: TextInput.propTypes.id,
    isValid: TextInput.propTypes.isValid,
    label: TextInput.propTypes.label,
    minimal: TextInput.propTypes.minimal,
    name: TextInput.propTypes.name,
    options: TextInput.propTypes.options.isRequired,
    placeholder: TextInput.propTypes.placeholder,
    value: TextInput.propTypes.value,
};
