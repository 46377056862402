import { gracefulDegradationMessage } from '../../shared/errorMessages';
import { AUTH_CHECK_IDENTIFIER_PATH } from '../../shared/endpoints/auth';
import {
    ValidateUserIdentifierRequestBody,
    ValidateUserIdentifierResponseBody,
} from '../../shared/requests';
import { makeRequest } from '../fetch';

export async function checkUserIdentifierUniqueness(userIdentifier: string) {
    try {
        return await makeRequest<
            ValidateUserIdentifierRequestBody,
            ValidateUserIdentifierResponseBody
        >(AUTH_CHECK_IDENTIFIER_PATH, {
            method: 'post',
            body: { userIdentifier },
        });
    } catch (error) {
        throw new Error(gracefulDegradationMessage);
    }
}
