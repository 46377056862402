import { useCallback, useEffect, useRef, useState } from 'react';
import { useStateContext } from '../../modules/stateContext';
import { sendCustomEvent } from './sendCustomEvent';

type SendCustomEventOptions = Parameters<typeof sendCustomEvent>[0];

type UseSendCustomEventCallbackOptions = Omit<
    SendCustomEventOptions,
    'featureToggles'
> &
    Partial<SendCustomEventOptions>;

export function useSendCustomEvent() {
    const [success, setSuccess] = useState<boolean>();
    const mounted = useRef<boolean>();

    const [isLoading, setIsLoading] = useState(false);

    const {
        featureToggles: { generateUserOriginFromPtrt, reverbUserOrigin },
    } = useStateContext();

    const callSendCustomEvent = useCallback(
        async (options: UseSendCustomEventCallbackOptions) => {
            setIsLoading(true);

            const result = await sendCustomEvent({
                generateUserOriginFromPtrt,
                reverbUserOrigin,
                ...options,
            });

            if (mounted.current) {
                setSuccess(result);
                setIsLoading(false);
            }
        },
        [generateUserOriginFromPtrt, reverbUserOrigin]
    );

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    });

    return {
        isLoading,
        sendCustomEvent: callSendCustomEvent,
        success,
    };
}
