import React from 'react';
import { Button } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Track from '../shared/analytics/track';
import Layout from '../pageLayout/layout';
import Form from '../shared/form';

export function ThankYouWithoutMarketing({ action, title }) {
    return (
        <Layout heading={<FormattedMessage id={title} />}>
            <Track ignoreViews name="continue">
                <Form
                    action={action}
                    autoComplete="off"
                    method="post"
                    name="thank-you"
                    data-testid="form"
                >
                    <Button id="submit-button" isFullWidth isSubmit>
                        <FormattedMessage id="button.continue.value" />
                    </Button>
                </Form>
            </Track>
        </Layout>
    );
}

ThankYouWithoutMarketing.propTypes = {
    action: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
