import React from 'react';
import Text from '../../../shared/text';

const EmotionalVariant = () => (
    <>
        <Text>
            Discover content that’s right for adults or children by telling us
            who’s creating an account.
        </Text>
    </>
);

export default EmotionalVariant;
