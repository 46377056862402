import React, { useCallback } from 'react';
import { Heading, Hint } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import { Explainer } from '../../../../shared/explainer';
import FormattedMessageWithAttributes from '../../../../shared/formattedMessageWithAttributes';
import { PERSONAL_DETAILS_URL } from '../../../../../../shared/endpoints/usingTheBbc';
import { EmailFirstDateOfBirthFormInput } from './emailFirstDateOfBirthFormInput';

import FormError from '../../../../shared/formError';
import {
    EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME,
    EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES,
    useEmailFirstDateOfBirthField,
} from './useEmailFirstDateOfBirthField';
import { Fields } from './emailFirstDateOfBirthField.style';
import { isMonthDayYearCountry } from '../../../../../../shared/i18n/dateFormat';
import { useStateContext } from '../../../../../modules/stateContext';
import { isNorthAmericanCountry } from '../../../../../../shared/i18n/region';
import { isExUk } from '../../../../../utilities/isExUk';
import { isLocationUk } from '../../../../../../shared/utilities/isLocationUk';
import { HELP_FEDERATED_DOB_URL } from '../../../../../../shared/endpoints/account';

export { EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME } from './useEmailFirstDateOfBirthField';

const formInputAriaDescribedBy = `form-message-${EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME}`;

export const EmailFirstDateOfBirthField = () => {
    const { location, isFederated } = useStateContext();
    const { error, handleInputBlur } = useEmailFirstDateOfBirthField();
    const isMonthFirst =
        isExUk(location) && isMonthDayYearCountry(location.country);

    const dayField = (
        <EmailFirstDateOfBirthFormInput
            aria-describedby={formInputAriaDescribedBy}
            autoComplete="bday-day"
            id={EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.DAY}
            isInvalid={!!error}
            name={EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.DAY}
            label="label.day"
            onBlur={handleInputBlur}
        />
    );
    const monthField = (
        <EmailFirstDateOfBirthFormInput
            aria-describedby={formInputAriaDescribedBy}
            autoComplete="bday-month"
            id={EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.MONTH}
            isInvalid={!!error}
            name={EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.MONTH}
            label="label.month"
            onBlur={handleInputBlur}
        />
    );
    const yearField = (
        <EmailFirstDateOfBirthFormInput
            aria-describedby={formInputAriaDescribedBy}
            autoComplete="bday-year"
            id={EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.YEAR}
            isInvalid={!!error}
            name={EMAIL_FIRST_DATE_OF_BIRTH_FORM_FIELD_NAMES.YEAR}
            label="label.year"
            onBlur={handleInputBlur}
        />
    );

    const fields = isMonthFirst ? (
        <>
            {monthField}
            {dayField}
            {yearField}
        </>
    ) : (
        <>
            {dayField}
            {monthField}
            {yearField}
        </>
    );

    const getExplainerKey = useCallback(() => {
        const { country } = location;
        const isUk = isLocationUk(location);

        if (isNorthAmericanCountry(country)) {
            return 'explainer.message.northAmerica.age';
        }

        if (!isUk) {
            return 'explainer.message.international.age';
        }

        return 'explainer.message.uk.age';
    }, [location]);

    return (
        <fieldset>
            <legend className="u-margin-bottom u-padding-top-quad">
                <Heading
                    level={2}
                    fontScale="sectionHeading"
                    fontWeight="regular"
                >
                    <FormattedMessage id="label.dateOfBirth" />
                </Heading>
            </legend>
            <Hint>
                {isMonthFirst ? (
                    <FormattedMessage id="label.dateOfBirthMonthFirstHint" />
                ) : (
                    <FormattedMessage id="label.dateOfBirthHint" />
                )}
            </Hint>

            <Fields data-testid="date-of-birth-fields">{fields}</Fields>

            {error && (
                <FormError
                    error={error}
                    name={EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME}
                    migrateToIdComponents
                />
            )}

            <Explainer
                jsEnabled
                explainers={
                    <FormattedMessageWithAttributes
                        id={getExplainerKey()}
                        attributes={{
                            href: isFederated
                                ? HELP_FEDERATED_DOB_URL
                                : PERSONAL_DETAILS_URL,
                            external: true,
                            target: '_blank',
                        }}
                    />
                }
                name={EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME}
            />
        </fieldset>
    );
};
