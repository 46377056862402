import { NMA_REALM, Realm } from '../../data/realms';

type GetAppTypeOptions = {
    isFederated?: boolean;
    realm?: Realm;
};

export function getAppType({ isFederated, realm }: GetAppTypeOptions) {
    if (realm === NMA_REALM) {
        return 'mobile-app';
    }

    if (isFederated) {
        return 'federated-web';
    }

    return 'web';
}
