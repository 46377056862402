import { gracefulDegradationMessage } from '../../shared/errorMessages';
import { makeRequest } from '../fetch';

class PostcodeResultCache {
    #results;

    constructor() {
        this.#results = {};
    }

    set(postcode, result) {
        this.#results[postcode] = result;
    }

    get(postcode) {
        const result = this.#results[postcode];

        if (typeof result === 'undefined') {
            throw new TypeError(
                `PostcodeResultCache Error: no result found for postcode ${postcode}`
            );
        }

        return result;
    }

    has(postcode) {
        return !!this.get(postcode);
    }

    remove(postcode) {
        delete this.#results[postcode];
    }

    removeAll() {
        Object.keys(this.#results).forEach(postcode => {
            this.remove(postcode);
        });
    }
}

export const postcodeResultCache = new PostcodeResultCache();

export async function checkPostcode(postcode, { ignoreCache } = {}) {
    try {
        if (ignoreCache) {
            postcodeResultCache.remove(postcode);
        }

        let hasCachedPostCodeResult;

        try {
            hasCachedPostCodeResult = postcodeResultCache.has(postcode);
            // eslint-disable-next-line no-empty
        } catch (err) {}

        if (hasCachedPostCodeResult) {
            return postcodeResultCache.get(postcode);
        }

        const result = await makeRequest(
            `/register/details/validate/postcode?postcode=${encodeURIComponent(
                postcode
            )}`
        );

        postcodeResultCache.set(postcode, result);

        return result;
    } catch (error) {
        return { success: false, message: gracefulDegradationMessage };
    }
}
