import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AuthLayout from '../components/auth/layout';
import MagicLinkVerification from '../components/auth/pages/magicLinkValidation';
import MagicLinkError from '../components/auth/pages/magicLinkError';
import MagicLinkAuthenticateSuccess from '../components/auth/pages/magicLinkAuthenticateSuccess';
import VerifyPassword from '../components/signIn/verifyPassword';
import MagicLinkPasswordSuccess from '../components/auth/pages/magicLinkPasswordSuccess';
import { useStateContext } from '../modules/stateContext';
import MagicLinkValidateApprove from '../components/auth/pages/magicLinkValidateApprove';
import MagicLinkValidateCancel from '../components/auth/pages/magicLinkValidateCancel';
import MagicLinkValidateClientSuccess from '../components/auth/pages/magicLinkValidateClientSuccess';

function Auth() {
    const { featureToggles } = useStateContext();

    return (
        <Switch>
            <Route
                path="/auth/magic-link/validate"
                component={
                    featureToggles.ipBlocking
                        ? MagicLinkValidateApprove
                        : MagicLinkVerification
                }
                exact
            />
            <Route
                path="/auth/magic-link/validate/success"
                component={MagicLinkVerification}
                exact
            />
            <Route
                path="/auth/magic-link/validate/cancel"
                component={MagicLinkValidateCancel}
                exact
            />
            <Route
                path="/auth/magic-link/validate/checked"
                component={MagicLinkVerification}
                exact
            />
            <Route
                path="/auth/magic-link/validate/client/success"
                component={MagicLinkValidateClientSuccess}
            />
            <Route
                path="/auth/magic-link/validate/client/:clientId"
                component={
                    featureToggles.ipBlocking
                        ? MagicLinkValidateApprove
                        : MagicLinkValidateClientSuccess
                }
                exact
            />
            <Route
                path="/auth/magic-link/validate/error"
                component={MagicLinkError}
            />
            <Route
                path="/auth/magic-link/authenticate/error"
                component={MagicLinkError}
            />
            <Route
                path="/auth/magic-link/authenticate/success"
                component={MagicLinkAuthenticateSuccess}
                exact
            />
            <Route
                path="/auth/magic-link/password"
                component={VerifyPassword}
                exact
            />
            <Route
                path="/auth/magic-link/password/success"
                component={MagicLinkPasswordSuccess}
                exact
            />
            <Route path="*" component={AuthLayout} />
        </Switch>
    );
}

export default Auth;
