import classNames from 'classnames';
import { TextInput } from '@bbc-account/id-components';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { errorShape } from '../../../../../propTypes';
import FormError from '../../../../shared/formError';
import { useEmailFirstTextFormInputField } from './useEmailFirstTextFormInputField';
import { StyledEmailFirstTextFormInput } from './emailFirstTextFormInput.style';

export const EmailFirstTextFormInput = ({
    'aria-describedby': ariaDescribedBy,
    as: Input,
    autoComplete,
    children,
    className,
    defaultValue,
    disableIsValid,
    message,
    hasShowPasswordToggle,
    id,
    isInvalid,
    label,
    name,
    onBlur,
    onChange,
    onIsValidChange,
    options,
    type,
    validator,
    warning,
}) => {
    const intl = useIntl();

    const formattedLabel = useMemo(() => intl.formatMessage({ id: label }), [
        intl,
        label,
    ]);

    const {
        errors,
        eventHandlers,
        isValid,
        value,
    } = useEmailFirstTextFormInputField({
        defaultValue,
        id,
        onBlur,
        onChange,
        onIsValidChange,
        validator,
    });

    const hasErrors = Array.isArray(errors) ? errors.length > 0 : !!errors;

    const isWarning = !!warning && !hasErrors;

    const messageComponent = useMemo(
        () =>
            typeof message === 'function'
                ? message({ errors })
                : (errors || warning) && (
                      <FormError
                          error={errors || warning}
                          isWarning={isWarning}
                          migrateToIdComponents={!isWarning}
                          name={name}
                      />
                  ),
        [message, errors, warning, isWarning, name]
    );

    return (
        <StyledEmailFirstTextFormInput
            className={classNames(className, 'field', {
                'field--with-warning': isWarning,
            })}
        >
            <Input
                aria-describedby={ariaDescribedBy || `form-message-${name}`}
                autoComplete={autoComplete}
                eventHandlers={eventHandlers}
                errorComponent={!isWarning && messageComponent}
                message={isWarning && messageComponent}
                hasShowPasswordToggle={hasShowPasswordToggle}
                id={id}
                isInvalid={isInvalid || hasErrors}
                isValid={!disableIsValid && isValid}
                label={formattedLabel}
                name={name}
                options={options}
                type={Input === 'select' ? undefined : type}
                value={value}
            >
                {children}
            </Input>
        </StyledEmailFirstTextFormInput>
    );
};

EmailFirstTextFormInput.propTypes = {
    'aria-describedby': PropTypes.string,
    as: TextInput.propTypes.as,
    autoComplete: TextInput.propTypes.autoComplete,
    children: TextInput.propTypes.children,
    className: PropTypes.string,
    defaultValue: TextInput.propTypes.value,
    disableIsValid: PropTypes.bool,
    message: PropTypes.func,
    hasShowPasswordToggle: TextInput.propTypes.hasShowPasswordToggle,
    id: TextInput.propTypes.id,
    isInvalid: TextInput.propTypes.isInvalid,
    label: TextInput.propTypes.label,
    name: TextInput.propTypes.name,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onIsValidChange: PropTypes.func,
    options: TextInput.propTypes.options,
    type: TextInput.propTypes.type,
    validator: PropTypes.shape({
        validate: PropTypes.func.isRequired,
    }),
    warning: errorShape,
};

EmailFirstTextFormInput.defaultProps = {
    as: TextInput,
    type: 'text',
};
