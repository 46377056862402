import * as config from '../../../server/config';
import { sendPianoCustomEvent } from '../../../shared/events';
import { customEventsHttpClient } from './httpClient';

const isProdEnv = process.env.NODE_ENV === 'production';

const PUBLIC_SERVICE_ACCOUNT_ID: string = config.get(
    'atiPublicServiceAccountId'
);

type SendPianoCustomEventOptions = Parameters<typeof sendPianoCustomEvent>[0];

type SendCustomEventReverbDestination = {
    endpoint: string;
    id: string;
};

type SendCustomEventOptions = Omit<
    SendPianoCustomEventOptions,
    | 'accountId'
    | 'baseUrl'
    | 'generateUserOriginFromPtrt'
    | 'headers'
    | 'httpClient'
    | 'onError'
    | 'onSuccess'
> & {
    generateUserOriginFromPtrt?: boolean;
    reverbDestination?: SendCustomEventReverbDestination;
    reverbUserOrigin?: boolean;
};

export function sendCustomEvent({
    generateUserOriginFromPtrt,
    reverbDestination,
    reverbUserOrigin,
    ...options
}: SendCustomEventOptions) {
    let accountId: string = PUBLIC_SERVICE_ACCOUNT_ID;

    let baseUrl: string | undefined;

    if (reverbUserOrigin && typeof reverbDestination !== 'undefined') {
        accountId = reverbDestination.id;
        baseUrl = `https://${reverbDestination.endpoint}/hit.xiti`;
    }

    return sendPianoCustomEvent({
        accountId,
        baseUrl,
        generateUserOriginFromPtrt,
        httpClient: customEventsHttpClient,
        // eslint-disable-next-line no-console
        onError: isProdEnv ? undefined : console.error,
        // eslint-disable-next-line no-console
        onSuccess: isProdEnv ? undefined : console.info,
        ...options,
    });
}
