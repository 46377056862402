import { styled } from 'styled-components';

export const StyledInstruction = styled.li`
    list-style-type: none;
    margin-top: var(--spacing-5);
    margin-bottom: var(--spacing-4);
`;

export const StyledInstructionIcon = styled.span`
    background: var(--core-account);
    color: var(--full-white);
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    display: inline-flex;
    padding: var(--spacing-3);
    width: var(--spacing-5);
    height: var(--spacing-5);
    margin-right: var(--spacing-2);
`;
