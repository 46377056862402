export function getSessionStorage() {
    try {
        const { sessionStorage } = global;

        if (!(typeof sessionStorage === 'undefined')) {
            return sessionStorage;
        }
    } catch (err) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn(err);
        }
    }

    return undefined;
}

export function getSessionStorageItemAndRemove(key) {
    const sessionStorage = getSessionStorage();

    if (typeof sessionStorage === 'undefined') {
        return undefined;
    }

    const value = sessionStorage.getItem(key);

    sessionStorage.removeItem(key);

    return value;
}

export function useSessionStorage() {
    const sessionStorage = getSessionStorage();

    return {
        getSessionStorageItemAndRemove,
        sessionStorage,
    };
}
