import FunctionalShortVariant from './functionalShortVariant';
import FunctionalLongVariant from './functionalLongVariant';
import EmotionalVariant from './emotionalVariant';

const experimentVariantComponentMap = {
    func_short: FunctionalShortVariant,
    func_long: FunctionalLongVariant,
    emotional: EmotionalVariant,
};

export default experimentVariantComponentMap;
