import { useFieldState } from '@bbc-account/id-formaxe';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { gender, genderOther } from '@bbc-account/id-validators';
import { Explainer } from '../../../../shared/explainer';
import FormattedMessageWithAttributes from '../../../../shared/formattedMessageWithAttributes';
import { PERSONAL_DETAILS_URL } from '../../../../../../shared/endpoints/usingTheBbc';
import { EmailFirstSelectFormInput } from '../select';
import { EmailFirstTextFormInput } from '../text';
import { StyledEmailFirstTextFormInput } from './emailFirstGenderFormInput.style';

export const EMAIL_FIRST_GENDER_FORM_INPUT_NAME = 'gender';

const GENDERS = {
    FEMALE: 'female',
    MALE: 'male',
    NON_BINARY: 'non-binary',
    OTHER: 'other',
    PREFER_NOT_TO_SAY: 'prefer not to say',
};

export const EmailFirstGenderFormInput = ({ autoComplete, defaultValue }) => {
    const { value } = useFieldState(EMAIL_FIRST_GENDER_FORM_INPUT_NAME);

    const intl = useIntl();

    const validator = useMemo(
        () => ({
            validate: currentValue => gender.validate([currentValue || '']),
        }),
        []
    );

    const genderOptions = useMemo(
        () => [
            {
                disabled: true,
                label: intl.formatMessage({ id: 'select.prompt' }),
                value: '',
            },
            {
                label: intl.formatMessage({ id: 'gender.female' }),
                value: GENDERS.FEMALE,
            },
            {
                label: intl.formatMessage({ id: 'gender.male' }),
                value: GENDERS.MALE,
            },
            {
                label: intl.formatMessage({ id: 'gender.nonBinary' }),
                value: GENDERS.NON_BINARY,
            },
            {
                label: intl.formatMessage({ id: 'gender.other' }),
                value: GENDERS.OTHER,
            },
            {
                label: intl.formatMessage({ id: 'gender.preferNotToSay' }),
                value: GENDERS.PREFER_NOT_TO_SAY,
            },
        ],
        [intl]
    );

    return (
        <div>
            <EmailFirstSelectFormInput
                autoComplete={autoComplete}
                defaultValue={defaultValue}
                id={EMAIL_FIRST_GENDER_FORM_INPUT_NAME}
                label="label.gender.title"
                name={EMAIL_FIRST_GENDER_FORM_INPUT_NAME}
                options={genderOptions}
                validator={validator}
            />
            {value === GENDERS.OTHER && (
                <StyledEmailFirstTextFormInput
                    disableIsValid
                    id="genderOther"
                    label="label.prompt.title"
                    name="genderOther"
                    validator={genderOther}
                >
                    <div className="field__note">
                        <FormattedMessage id="field.nonMandatory" />
                    </div>
                </StyledEmailFirstTextFormInput>
            )}
            <Explainer
                explainers={
                    <FormattedMessageWithAttributes
                        id="explainer.message.gender"
                        attributes={{
                            external: true,
                            href: PERSONAL_DETAILS_URL,
                        }}
                    />
                }
                jsEnabled
                name={EMAIL_FIRST_GENDER_FORM_INPUT_NAME}
            />
        </div>
    );
};

EmailFirstGenderFormInput.propTypes = {
    autoComplete: EmailFirstTextFormInput.propTypes.autoComplete,
    defaultValue: EmailFirstTextFormInput.propTypes.defaultValue,
};
