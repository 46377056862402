import { gracefulDegradationMessage } from '../../shared/errorMessages';
import {
    AUTH_GENERATE_OTP_PATH,
    AUTH_REGISTER,
    AUTH_REGISTER_FEDERATED,
    AUTH_VALIDATE_OTP_PATH,
} from '../../shared/endpoints/authV2';
import { makeRequest } from '../fetch';

export const register = async ({ paramsString, values, userContext }) => {
    try {
        const { isFederated } = userContext;

        const registerEndpoint = isFederated
            ? AUTH_REGISTER_FEDERATED
            : AUTH_REGISTER;

        const url = new URL(registerEndpoint, window.location.origin);

        if (paramsString) {
            const urlSearchParams = new URLSearchParams(paramsString);

            urlSearchParams.forEach((value, key) => {
                url.searchParams.append(key, value);
            });
        }

        return await makeRequest(url.toString(), {
            method: 'post',
            body: values,
        });
    } catch (error) {
        if (process.env.NODE_ENV === 'development') {
            console.error(error);
        }

        return { success: false, message: gracefulDegradationMessage };
    }
};

export const generateOtp = async (email, params) => {
    try {
        const GENERATE_OTP_ENDPOINT = `${AUTH_GENERATE_OTP_PATH}${params}`;

        return await makeRequest(GENERATE_OTP_ENDPOINT, {
            method: 'post',
            body: { email },
        });
    } catch (error) {
        return { success: false, message: gracefulDegradationMessage };
    }
};

export const verifyOtp = async (email, otp, otpJwt, params) => {
    try {
        const VERIFY_OTP_ENDPOINT = `${AUTH_VALIDATE_OTP_PATH}${params}`;

        return await makeRequest(VERIFY_OTP_ENDPOINT, {
            method: 'post',
            body: { email, otp, otpJwt },
        });
    } catch (error) {
        return { success: false, message: gracefulDegradationMessage };
    }
};
