import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FlexContainer } from '../../gel/containers/flex/flexContainer';
import './radio.scss';

export const Radio = ({
    className,
    disabled,
    errorComponent,
    id,
    isInvalid: isInvalidProp,
    label,
    labelSize,
    name,
    reverse,
    ...props
}) => {
    const isInvalid = isInvalidProp || !!errorComponent;

    return (
        <div
            className={classnames('sb-field sb-field__radio', {
                'sb-field--invalid': isInvalid,
            })}
        >
            <FlexContainer alignCenter reverse={reverse}>
                <div
                    className={classnames('sb-radio__container', {
                        'sb-radio__container--disabled': disabled,
                    })}
                >
                    <input
                        className={classnames('sb-radio', {
                            className,
                        })}
                        disabled={disabled}
                        id={id}
                        name={name}
                        type="radio"
                        {...props}
                    />
                    <svg
                        className="sb-radio__circle-icon"
                        height="1em"
                        viewBox="0 0 50 50"
                        width="1em"
                    >
                        <circle fill="currentColor" cx="25" cy="25" r="25" />
                    </svg>
                </div>
                <label
                    className={classnames('sb-radio__label', {
                        'sb-radio__label--large': labelSize === 'large',
                        'sb-radio__label--small': labelSize === 'small',
                    })}
                    htmlFor={id}
                >
                    {label}
                </label>
            </FlexContainer>
            {isInvalid && (
                <>
                    <div className="sb-radio__field-decoration" />
                    <div className="sb-radio__error-component-container">
                        {errorComponent}
                    </div>
                </>
            )}
        </div>
    );
};

Radio.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    errorComponent: PropTypes.node,
    id: PropTypes.string,
    isInvalid: PropTypes.bool,
    label: PropTypes.node,
    labelSize: PropTypes.oneOf(['small', 'large']),
    name: PropTypes.string,
    reverse: PropTypes.bool,
};

Radio.defaultProps = {
    labelSize: 'large',
};

Radio.displayName = 'Radio';
