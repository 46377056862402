import { gracefulDegradationMessage } from '../../shared/errorMessages';
import renderQueryString from '../../shared/urls/renderQueryString';
import { makeRequest } from '../fetch';

const requestMagicLink = async (email, userContext) => {
    try {
        const params = renderQueryString.call(userContext);

        return await makeRequest(`/auth/identifier/magic-link${params}`, {
            method: 'post',
            body: { email },
        });
    } catch (error) {
        return { success: false, message: gracefulDegradationMessage };
    }
};

export default requestMagicLink;
