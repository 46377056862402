import React from 'react';
import PropTypes from 'prop-types';
import experimentVariantComponentMap from './experimentVariantComponentMap';

const ExperimentAgeGateCopy = ({ variant }) => {
    const ExperimentVariantComponent = experimentVariantComponentMap[variant];

    return <ExperimentVariantComponent />;
};

ExperimentAgeGateCopy.propTypes = {
    variant: PropTypes.oneOf(Object.keys(experimentVariantComponentMap)),
};

export default ExperimentAgeGateCopy;
