import { gracefulDegradationMessage } from '../../shared/errorMessages';
import { AUTH_CHECK_EMAIL_PATH } from '../../shared/endpoints/authV2';
import {
    CheckEmailUniquenessRequestBody,
    CheckEmailUniquenessResponseBody,
} from '../../shared/requests';
import { makeRequest } from '../fetch';

export async function checkEmailUniqueness(email: string) {
    try {
        return await makeRequest<
            CheckEmailUniquenessRequestBody,
            CheckEmailUniquenessResponseBody
        >(AUTH_CHECK_EMAIL_PATH, {
            method: 'post',
            body: { email },
        });
    } catch (error) {
        throw new Error(gracefulDegradationMessage);
    }
}
