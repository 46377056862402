export const BBC_REALM = 'bbc';

export const FEDERATED_REALM = 'FederatedSignInRealm';

export const NMA_REALM = 'NMARealm';

export const TOP_LEVEL_REALM = '/';

const realms = {
    NMARealm: {
        canFederate: true,
        allowUnder13s: true,
    },
    Account: {
        canFederate: false,
        allowUnder13s: true,
    },
    FederatedSignInRealm: {
        canFederate: true,
        allowUnder13s: false,
    },
    bbc: {
        canFederate: true,
        allowUnder13s: false,
    },
};

export type Realm = keyof typeof realms | typeof TOP_LEVEL_REALM;

export type RealmName = Exclude<Realm, typeof TOP_LEVEL_REALM>;

function getRealm(realmName: RealmName) {
    return realms[realmName] || realms.Account;
}

export function canFederate(realmName: RealmName) {
    return getRealm(realmName).canFederate;
}

export function allowUnder13s(realmName: RealmName) {
    return getRealm(realmName).allowUnder13s;
}

export function isValidRealm(realm: Realm) {
    const validRealms = [
        NMA_REALM,
        FEDERATED_REALM,
        TOP_LEVEL_REALM,
        BBC_REALM,
    ];

    return validRealms.includes(realm);
}
