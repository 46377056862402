import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isCrownDependency } from '../../../../../../shared/data/crownDependencies';
import { getEmailFirstRoutes } from '../../../../../pages/email-first/emailFirstRouteNames';
import { EmailFirstForm } from '../../emailFirstForm';
import {
    EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME,
    EMAIL_FIRST_GENDER_FORM_INPUT_NAME,
    EMAIL_FIRST_POSTCODE_FORM_INPUT_NAME,
    EmailFirstDateOfBirthField,
    EmailFirstGenderFormInput,
    EmailFirstPostCodeFormInput,
} from '../../inputs';
import { useEmailFirstForm } from '../../useEmailFirstForm';
import { StyledTermsAndConditions } from '../emailFirstDetailsForm.style';
import { useStateContext } from '../../../../../modules/stateContext';

const activeFields = [
    EMAIL_FIRST_DATE_OF_BIRTH_FORM_INPUT_NAME,
    EMAIL_FIRST_GENDER_FORM_INPUT_NAME,
    EMAIL_FIRST_POSTCODE_FORM_INPUT_NAME,
];

// TODO the countryCode is hardcoded as we have removed the
// field for the user to input. Should this be handled in some other way?
const countryCode = 'gb';

export const EmailFirstDetailsForm = () => {
    const { isFederated, isSingleEntryAuth } = useStateContext();

    const { emailFirstPaths } = getEmailFirstRoutes(
        isFederated,
        isSingleEntryAuth
    );

    const {
        fieldValues,
        formError,
        handleBack,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        countryCode,
        prevRoute: emailFirstPaths.password.path,
        submitToRegister: true,
    });

    const isInternational = !isCrownDependency(countryCode);

    return (
        <EmailFirstForm
            activeFields={activeFields}
            heading={<FormattedMessage id="emailFirst.detailsForm.title" />}
            formError={formError}
            onBackButtonClick={handleBack}
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
            pageId="registration-email-first-details"
            submitButtonLabel="register.button.submit.value"
        >
            <EmailFirstDateOfBirthField />
            <EmailFirstPostCodeFormInput
                defaultValue={fieldValues && fieldValues.postcode}
            />
            <EmailFirstGenderFormInput
                defaultValue={(fieldValues && fieldValues.gender) || ''}
            />

            <StyledTermsAndConditions
                isInternational={isInternational}
                linkTarget="_blank"
                messageId="register.message.acceptTerms"
            />
        </EmailFirstForm>
    );
};
