import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useFieldState, useFieldValidation } from '@bbc-account/id-formaxe';
import { TextInput } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { emailOrUsername } from '../../../../shared/validation/validators';
import { ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY } from '../../forms';
import FormError from '../../shared/formError';

const IdentifierInput = ({
    name = 'username',
    label = <FormattedMessage id="common.label.emailOrUsername" />,
    onBlur,
}) => {
    const { value, state, eventHandlers } = useFieldState(name);
    const { validate, errors } = useFieldValidation(
        name,
        emailOrUsername.validate
    );

    const {
        getSessionStorageItemAndRemove,
        sessionStorage,
    } = useSessionStorage();

    const cachedValue = useMemo(() => {
        if (sessionStorage) {
            const identifier = getSessionStorageItemAndRemove(
                ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY
            );

            return identifier || value;
        }

        return value;
    }, [sessionStorage, value]);

    const hasErrors = Array.isArray(errors) ? errors.length > 0 : !!errors;

    return (
        <TextInput
            autoComplete="username"
            id="user-identifier-input"
            name={name}
            type="text"
            value={cachedValue}
            state={state}
            label={label}
            eventHandlers={{
                ...eventHandlers,
                onBlur:
                    onBlur ||
                    (event => {
                        validate(event.target.value);
                        eventHandlers.onBlur(event);
                    }),
                ...(hasErrors
                    ? {
                          onChange: event => {
                              validate(event.target.value);
                              eventHandlers.onChange(event);
                          },
                      }
                    : {}),
            }}
            isInvalid={hasErrors}
            message={
                <FormError
                    error={errors}
                    name={name}
                    migrateToIdComponents
                    isAssertive
                />
            }
        />
    );
};

IdentifierInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.node,
    onBlur: PropTypes.func,
};

export default IdentifierInput;
