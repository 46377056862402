import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getEmailFirstRoutes } from '../emailFirstRouteNames';
import { useStateContext } from '../../../modules/stateContext';

export function useEmailFirstRoutes(isSingleEntryAuth) {
    const intl = useIntl();

    const history = useHistory();

    const { path: routePath } = useRouteMatch();

    const { pathname, search } = useLocation();

    const { isFederated } = useStateContext();

    const { emailFirstPaths } = getEmailFirstRoutes(
        isFederated,
        isSingleEntryAuth
    );

    useEffect(() => {
        const route = Object.values(emailFirstPaths).find(
            emailFirstRoute => pathname === emailFirstRoute.path
        );

        if (route) {
            document.title = intl.formatMessage({ id: route.title });
        }
    }, [routePath, pathname]);

    useEffect(() => {
        const targetPath = emailFirstPaths.email.path;

        if (window.location.pathname !== targetPath) {
            history.replace(`${targetPath}${search}`);
        }
    }, [history]);

    return {
        search,
    };
}
