import { useEffect, useMemo } from 'react';

let linkWasClicked = false;

function beforeUnloadHandler(event) {
    if (!linkWasClicked) {
        event.preventDefault();

        // Included for legacy support
        event.returnValue = true;
    }
}

function isTargetAnchor({ nodeName, parentNode }) {
    switch (nodeName.toLowerCase()) {
        case 'a':
            return true;
        case 'button':
        case 'body':
        case 'document':
            return false;
        default:
            return parentNode ? isTargetAnchor(parentNode) : false;
    }
}

function documentClickHandler(event) {
    linkWasClicked = isTargetAnchor(event.target);

    if (linkWasClicked) {
        setTimeout(() => {
            linkWasClicked = false;
        }, 100);
    }
}

function removeEventListeners() {
    global.window?.removeEventListener('beforeunload', beforeUnloadHandler);
    global.document?.removeEventListener('click', documentClickHandler);
}

function addEventListeners() {
    global.window?.addEventListener('beforeunload', beforeUnloadHandler);
    global.document?.addEventListener('click', documentClickHandler);
}

export function useEmailFirstFormPageBeforeUnload({
    eventListenersEnabled = true,
    fieldValues,
}) {
    const hasUnsavedValues = useMemo(
        () => Object.values(fieldValues).some(value => !!value),
        [fieldValues]
    );

    if (eventListenersEnabled && hasUnsavedValues) {
        addEventListeners();
    } else {
        removeEventListeners();
    }

    useEffect(() => {
        return () => {
            removeEventListeners();
        };
    }, []);
}
