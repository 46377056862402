class IframePostMessageError extends Error {
    name = 'IframePostMessageError';

    constructor(...args) {
        super(...args);
        Error.captureStackTrace(this, IframePostMessageError);
    }
}

export const IFRAME_MESSAGE_TYPE = Object.freeze({
    ERROR: 'error',
    INFO: 'info',
});

const messageTypes = Object.values(IFRAME_MESSAGE_TYPE);

function prepareMessage(message) {
    switch (typeof message) {
        case 'object':
            if (!('message' in message)) {
                throw new IframePostMessageError('Missing "message" property');
            }

            if ('type' in message) {
                const { type: messageType } = message;

                const ok = messageTypes.indexOf(messageType) > -1;

                if (!ok) {
                    throw new IframePostMessageError(
                        `Invalid message type "${messageType}". Permitted message types: ${messageTypes}`
                    );
                }
            }

            return {
                type: IFRAME_MESSAGE_TYPE.INFO,
                ...message,
            };

        case 'string':
        default:
            if (!message) {
                throw new IframePostMessageError(`Missing message`);
            }

            return {
                message,
                type: IFRAME_MESSAGE_TYPE.INFO,
            };
    }
}

export function postMessageToParent(message, { targetOrigin } = {}) {
    if (typeof window !== 'undefined' && window.parent) {
        window.parent.postMessage(prepareMessage(message), targetOrigin);
    }
}

export function postErrorToParent(
    message,
    { featureToggles, targetOrigin } = {}
) {
    const preparedMessage = prepareMessage(message);

    preparedMessage.type = IFRAME_MESSAGE_TYPE.ERROR;

    postMessageToParent(preparedMessage, { targetOrigin, featureToggles });
}
