import * as idValidators from '@bbc-account/id-validators';
import {
    APPLICATION_VALID,
    APPLICATION_INVALID_BOOL,
    VALIDATORS_VALID,
    VALIDATORS_INVALID_BOOL,
} from './validationResult';

const validatorKeys = Object.keys(idValidators);

const isPlainJsObject = value =>
    !{}.hasOwnProperty.call(value, 'length') &&
    typeof value === 'object' &&
    value !== null;

const convertValidResult = result => {
    if (isPlainJsObject(result)) {
        const clone = { ...result };
        return Object.keys(clone).reduce((output, key) => {
            const value = output[key];
            output[key] = convertValidResult(value);
            return output;
        }, clone);
    }

    if (result === VALIDATORS_VALID) {
        return APPLICATION_VALID;
    }

    if (result === VALIDATORS_INVALID_BOOL) {
        return APPLICATION_INVALID_BOOL;
    }

    return result;
};

const wrapValidator = validator => {
    const clone = { ...validator };

    const wrappedValidator = Object.keys(clone).reduce((result, key) => {
        const subValidator = validator[key];

        if (typeof subValidator === 'function') {
            result[key] = (...args) => {
                const validationResult = subValidator(...args);
                return convertValidResult(validationResult);
            };
        }

        return result;
    }, clone);

    return wrappedValidator;
};

const validators = validatorKeys.reduce((result, key) => {
    result[key] = wrapValidator(idValidators[key]);
    return result;
}, {});

const {
    date,
    dateOfBirth,
    displayName,
    email,
    emailOrUsername,
    gender,
    genderOther,
    geoname,
    hometown,
    inArray,
    length,
    marketingOptIn,
    matching,
    oneTimePassword,
    password,
    postcode,
    string,
    token,
    tvPin,
    username,
} = validators;

export {
    date,
    dateOfBirth,
    displayName,
    email,
    emailOrUsername,
    gender,
    genderOther,
    geoname,
    hometown,
    inArray,
    length,
    marketingOptIn,
    matching,
    oneTimePassword,
    password,
    postcode,
    string,
    token,
    tvPin,
    username,
};
