const authPages = {
    EMAIL_FORM: 'EmailForm',
    IDENTIFIER_FORM: 'IdentifierForm',
    IFRAME_IDENTIFIER_FORM: 'IframeIdentifierForm',
    IFRAME_PASSWORD_FORM: 'IframePasswordForm',
    IFRAME_MAGIC_LINK_CONFIRMATION: 'IframeMagicLinkConfirmation',
    MAGIC_LINK_CONFIRMATION: 'MagicLinkConfirmation',
    PASSWORD_FORM: 'PasswordForm',
    SINGLE_ENTRY_AUTH: 'SingleEntryAuth',
};

export default authPages;
