import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import {
    EmailFirstEmailForm,
    EmailFirstPasswordForm,
    EmailFirstDetailsForm,
    EmailFirstDetailsFormExUk,
} from '../../../components/forms/email-first';
import { useShowExUkContent } from '../../../hooks/useShowExUkContent';
import { getEmailFirstRoutes } from '../emailFirstRouteNames';
import { useEmailFirstRoutes } from './useEmailFirstRoutes';
import { useStateContext } from '../../../modules/stateContext';
import { EmailFirstLoginForm } from '../../../components/forms/email-first/login/emailFirstLoginForm';
import { EmailFirstMagicLinkConfirmation } from '../../../components/forms/email-first/magic-link';
import { EmailFirstVerificationForm } from '../../../components/forms/email-first/verification';
import { EMAIL_VERIFICATION_OPTIMIZELY_EXPERIMENT } from '../../../../shared/data/mvtExperiments';
import { useExperiment } from '../../../experiments/useExperiment';

export const EmailFirstRoutes = () => {
    const {
        isFederated,
        isSingleEntryAuth,
        featureToggles,
        location: { isUk },
    } = useStateContext();
    const { search } = useEmailFirstRoutes(isSingleEntryAuth);
    const isEmailVerificationExperimentEnabled =
        useExperiment(EMAIL_VERIFICATION_OPTIMIZELY_EXPERIMENT.NAME) === 'on';

    const showExUkContent = useShowExUkContent();

    const isEmailVerificationEnabled =
        isEmailVerificationExperimentEnabled &&
        featureToggles.accountEmailVerification &&
        isUk;

    const { emailFirstPaths, emailFirstBasePath } = getEmailFirstRoutes(
        isFederated,
        isSingleEntryAuth
    );

    const DetailsForm = showExUkContent
        ? EmailFirstDetailsFormExUk
        : EmailFirstDetailsForm;

    const emailPath = emailFirstPaths.email.path;
    const passwordPath = emailFirstPaths.password.path;
    const detailsPath = emailFirstPaths.details.path;
    const emailVerificationPath = emailFirstPaths.verification.path;
    const rootPath = emailFirstBasePath;

    return (
        <Switch>
            <Route exact path={emailPath} component={EmailFirstEmailForm} />
            <Route
                exact
                path={passwordPath}
                component={EmailFirstPasswordForm}
            />
            {isSingleEntryAuth && (
                <Route
                    exact
                    path={emailFirstPaths.login.path}
                    component={EmailFirstLoginForm}
                />
            )}
            {isSingleEntryAuth && (
                <Route
                    exact
                    path={emailFirstPaths.magicLink.path}
                    component={EmailFirstMagicLinkConfirmation}
                />
            )}
            {isEmailVerificationEnabled && (
                <Route
                    exact
                    path={emailVerificationPath}
                    component={EmailFirstVerificationForm}
                />
            )}
            <Route exact path={detailsPath} component={DetailsForm} />
            <Route path={`${rootPath}/*`}>
                <Redirect to={`${emailFirstPaths.email.path}${search}`} />
            </Route>
        </Switch>
    );
};
