import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@bbc-account/id-formaxe';
import { useHistory } from 'react-router-dom';
import {
    EMAIL_FIRST_EMAIL_FORM_INPUT_NAME,
    EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME,
    EmailFirstPasswordFormInput,
} from '../inputs';
import { useStateContext } from '../../../../modules/stateContext';
import { usePageBeforeUnloadEventDispatchContext } from '../../../../pages/events';
import { useEmailFirstForm } from '../useEmailFirstForm';
import signIn from '../../../../services/signIn';
import { PAGE_BEFORE_UNLOAD_EVENT_CONTEXT_REDUCER_ACTIONS } from '../../../../pages/events/beforeUnload/pageBeforeUnloadEventContextReducer';
import { EmailFirstForm } from '../emailFirstForm';
import { getEmailFirstRoutes } from '../../../../pages/email-first/emailFirstRouteNames';
import { usePurpose } from '../../../../context/purpose';
import requestMagicLink from '../../../../services/requestMagicLink';
import renderQueryString from '../../../../../shared/urls/renderQueryString';
import { EditUserIdentifier } from '../../editUserIdentifier';

const activeFields = [EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME];

export const EmailFirstLoginForm = () => {
    const [formError, setFormError] = useState();

    const history = useHistory();

    const userContext = useStateContext();

    const { setFieldValue } = useFormContext();

    const { isSubscription: isPurposeSubscription } = usePurpose();

    const dispatchPageBeforeUnloadEvent = usePageBeforeUnloadEventDispatchContext();

    const { emailFirstPaths } = getEmailFirstRoutes(
        userContext.isFederated,
        userContext.isSingleEntryAuth
    );

    const {
        fieldValues,
        handleBack,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        prevRoute: emailFirstPaths.email.path,
        preSubmit: async () => {
            const {
                sessionUrl: newSessionUrl,
                message: signinErrorMessage,
            } = await signIn(
                fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME],
                fieldValues[EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME],
                userContext
            );

            if (newSessionUrl) {
                window.location.assign(newSessionUrl);
            } else {
                setFormError(signinErrorMessage);
            }

            return false;
        },
    });

    const headingId = useMemo(() => {
        if (isPurposeSubscription) {
            return 'emailFirst.subscription.passwordForm.title';
        }

        return 'auth.password.title.introduction';
    }, [isPurposeSubscription]);

    const onMagicLinkSubmit = useCallback(
        async event => {
            event.preventDefault();

            const email = fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME];

            const { success, jti, traceId, message } = await requestMagicLink(
                email,
                userContext
            );

            if (!success) {
                setFormError(message);
                return;
            }

            setFieldValue('magicLink', {
                email,
                jti,
                traceId,
            });

            history.push(
                `/auth/identifier/magic-link/check${renderQueryString.call(
                    userContext
                )}`
            );
        },
        [userContext, setFieldValue, history, fieldValues]
    );

    useEffect(() => {
        dispatchPageBeforeUnloadEvent({
            type:
                PAGE_BEFORE_UNLOAD_EVENT_CONTEXT_REDUCER_ACTIONS.DISABLE_BEFORE_UNLOAD_EVENT_LISTENERS,
        });
    }, []);

    const onEditUserIdentifierEdit = useCallback(() => {
        history.push(
            `${emailFirstPaths.email.path}${renderQueryString.call({
                ...userContext,
            })}`
        );
    }, [history, userContext, emailFirstPaths]);

    return (
        <EmailFirstForm
            activeFields={activeFields}
            formError={formError}
            heading={<FormattedMessage id={headingId} />}
            layoutContentContainerClassName={
                userContext.isSingleEntryAuth && 'u-margin-top'
            }
            onBackButtonClick={handleBack}
            onSubmit={handleSubmit}
            submitButtonLabel={
                isPurposeSubscription
                    ? 'subscription.checkoutButton.label'
                    : 'emailFirst.identifier.login'
            }
            onSubmitInvalid={handleSubmitInvalid}
            withMagicLinkCta={userContext.featureToggles.magicLinks}
            onMagicLinkSubmit={onMagicLinkSubmit}
        >
            {userContext.isSingleEntryAuth && (
                <EditUserIdentifier
                    userIdentifier={fieldValues.username}
                    onClick={onEditUserIdentifierEdit}
                />
            )}
            <EmailFirstPasswordFormInput
                defaultValue={fieldValues && fieldValues.password}
                shouldValidate
                showChecklist={false}
                checkPasswordBreach={false}
            />
        </EmailFirstForm>
    );
};
