import url from 'url';
import { makeRequest } from '../fetch';

const asyncValidate = (fieldName, value, pathname, lang) => {
    const validateUrl = url.format({
        pathname,
        query: {
            [fieldName]: value,
            lang,
        },
    });

    return makeRequest(validateUrl, {
        method: 'GET',
    })
        .catch(() => ({
            // if async call fails mark as validation success so user can continue
            success: true,
            asyncCallSuccess: false,
        }))
        .then(({ success, message, asyncCallSuccess = true }) => {
            if (!success) {
                // eslint-disable-next-line no-throw-literal
                throw {
                    [fieldName]: message,
                };
            }

            return asyncCallSuccess;
        });
};

export default asyncValidate;
