import React from 'react';
import { FormattedMessage } from 'react-intl';
import Text from '../../../../shared/text';
import NavigationLinkContainer from '../../../../shared/navigationLinkContainer';
import { getEmailFirstRoutes } from '../../../../../pages/email-first/emailFirstRouteNames';
import { useStateContext } from '../../../../../modules/stateContext';

export const EmailFirstVerificationCta = () => {
    const userContext = useStateContext();
    const { isFederated, isSingleEntryAuth } = userContext;

    const { emailFirstPaths } = getEmailFirstRoutes(
        isFederated,
        isSingleEntryAuth
    );

    const emailFormPath = emailFirstPaths.email.path;

    return (
        <>
            <Text>
                <FormattedMessage
                    id="emailFirst.verification.checkSpam"
                    values={{
                        b: content => <span className="u-bold">{content}</span>,
                    }}
                />
            </Text>

            <NavigationLinkContainer variant="primary" href={emailFormPath}>
                <FormattedMessage id="emailFirst.verification.checkEmail" />
            </NavigationLinkContainer>
        </>
    );
};
