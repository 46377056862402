import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CallToActionButton, Message } from '@bbc-account/id-components';
import Layout from '../../pageLayout/layout';
import Track from '../../shared/analytics/track';
import { useStateContext } from '../../../modules/stateContext';
import FormattedMessageWithAttributes from '../../shared/formattedMessageWithAttributes';
import { usePurpose } from '../../../context/purpose';

const MagicLinkAuthenticateSuccess = () => {
    const {
        ptrt: { value: ptrtValue },
    } = useStateContext();

    const { isSubscription: isPurposeSubscription } = usePurpose();

    return (
        <Layout
            pageId="signin-page"
            heading={<FormattedMessage id="auth.magicLink.signedIn.title" />}
            showServiceIdentifiers
        >
            <Track container="magic-link" ignoreViews name="go-to-ptrt">
                <CallToActionButton href={ptrtValue} isFullWidth>
                    <FormattedMessage
                        id={
                            isPurposeSubscription
                                ? 'subscription.checkoutButton.label'
                                : 'auth.magicLink.signedIn.continue'
                        }
                    />
                </CallToActionButton>
            </Track>

            <Message
                isInfo
                name="magic-link-signed-in"
                className="u-margin-top-double u-align-items-center"
            >
                <FormattedMessageWithAttributes
                    id="auth.magicLink.signedIn.resetPassword"
                    attributes={{
                        href: '/auth/magic-link/password',
                        withState: true,
                    }}
                />
            </Message>
        </Layout>
    );
};

export default MagicLinkAuthenticateSuccess;
