import { prependAccountBaseUrl } from '../utilities/prependUrl';

const AUTH_PATH = '/auth';
const SINGLE_ENTRY_AUTH_PATH = '/auth/identifier';
const AUTH_URL = prependAccountBaseUrl(AUTH_PATH);
const AUTH_MAGIC_LINK_AUTHENTICATE = prependAccountBaseUrl('/auth/magic-link');
const AUTH_MAGIC_LINK_AUTHENTICATE_API_PATH = '/api/magic-link/authenticate';
const AUTH_MAGIC_LINK_AUTHENTICATE_SUCCESS_URL = prependAccountBaseUrl(
    '/auth/magic-link/authenticate/success'
);
const AUTH_MAGIC_LINK_AUTHENTICATE_ERROR_URL = prependAccountBaseUrl(
    '/auth/magic-link/authenticate/error'
);
const AUTH_MAGIC_LINK_PASSWORD_SUCCESS_URL = prependAccountBaseUrl(
    '/auth/magic-link/password/success'
);
const AUTH_MAGIC_LINK_VALIDATE_PATH = '/auth/magic-link/validate';
const AUTH_MAGIC_LINK_VALIDATE_CANCEL_PATH = '/auth/magic-link/validate/cancel';
const AUTH_MAGIC_LINK_VALIDATE_CLIENT_PATH = '/auth/magic-link/validate/client';
const AUTH_MAGIC_LINK_VALIDATE_CHECKED_URL = prependAccountBaseUrl(
    '/auth/magic-link/validate/checked'
);
const AUTH_MAGIC_LINK_VALIDATE_CLIENT_SUCCESS_URL = prependAccountBaseUrl(
    '/auth/magic-link/validate/client/success'
);
const AUTH_MAGIC_LINK_VALIDATE_ERROR_URL = prependAccountBaseUrl(
    '/auth/magic-link/validate/error'
);
const AUTH_MAGIC_LINK_VALIDATE_SUCCESS_URL = prependAccountBaseUrl(
    '/auth/magic-link/validate/success'
);

const AUTH_PASSWORD_PATH = '/auth/password';
const CHILD_PERMISSIONS_AGE_CONFIRMATION_PATH =
    '/permissions/children/age-confirmation';
const CHILD_PERMISSIONS_EMAIL_EXPIRED_PATH = '/permissions/children/expired';
const CHILD_PERMISSIONS_EMAIL_INVALID_PATH = '/permissions/children/invalid';
const CHILD_PERMISSIONS_EMAIL_SENT_PATH = '/permissions/children/email-sent';
const CHILD_PERMISSIONS_INCORRECT_DOB_PATH =
    '/permissions/children/incorrect-date-of-birth';
const CHILD_PERMISSIONS_THANKS_PATH = '/permissions/children/thanks';
const CHILD_PERMISSIONS_MANAGEMENT_PATH = '/permissions/children/manage';
const CREATE_PASSWORD_PATH = '/signin/create-password';
const FORGOTTEN_PASSWORD_PATH = '/signin/forgotten';
const FORGOTTEN_PASSWORD_URL = prependAccountBaseUrl(FORGOTTEN_PASSWORD_PATH);
const FORGOTTEN_PASSWORD_FEDERATED_PATH = '/signin/federated/forgotten';
const FORGOTTEN_PASSWORD_FEDERATED_URL = prependAccountBaseUrl(
    FORGOTTEN_PASSWORD_FEDERATED_PATH
);
const CREATE_PASSWORD_VERIFY_PATH = '/signin/create-password/verify';
const FORGOTTEN_PASSWORD_VERIFY_PATH = '/signin/forgotten/verify';
const FORGOTTEN_PASSWORD_VERIFY_FEDERATED_PATH =
    '/signin/federated/forgotten/verify';
const FORGOTTEN_CREDENTIALS_PATH = '/signin/forgotten/credentials';
const FORGOTTEN_CREDENTIALS_FEDERATED_PATH =
    '/signin/federated/forgotten/credentials';
const HELP_FEDERATED_DOB_URL = prependAccountBaseUrl(
    '/help/federated/why-do-i-have-to-provide-my-dob-when-i-register'
);
const HELP_FEDERATED_PROBLEMS_WITH_MY_EMAIL_ADDRESS_PATH =
    '/help/federated/im-having-problems-with-my-email-address';
const HELP_FEDERATED_PROBLEMS_WITH_MY_USERNAME_PATH =
    '/help/federated/im-having-problems-with-my-username';
const HELP_FEDERATED_PROBLEMS_WITH_MY_PASSWORD_PATH =
    '/help/federated/im-having-problems-with-my-password';
const HELP_FEDERATED_TERMS_AND_CONDITIONS_PATH = '/help/federated/terms-of-use';
const HELP_FEDERATED_PRIVACY_PATH = '/help/federated/privacy';
const HELP_FEDERATED_WHAT_EMAILS_AND_NEWSLETTERS =
    '/help/federated/what-emails-and-newsletters-will-the-bbc-send-me';
const HELP_FEDERATED_PASSWORD_MOBILE_DEVICES_PATH =
    '/help/federated/passwords-mobile-devices';
const MAINTENANCE_PATH = '/account/error/maintenance';
const MORE_DETAILS_PATH = '/account/more-details';
const MORE_DETAILS_NEXT_STEPS_PATH = '/account/more-details/next-steps';
const MORE_DETAILS_LINKED_ACCOUNT_PATH = `/account/more-details/linked-account`;
const MORE_DETAILS_NEXT_STEPS_URL = prependAccountBaseUrl(
    MORE_DETAILS_NEXT_STEPS_PATH
);
const MORE_DETAILS_LINKED_ACCOUNT_URL = prependAccountBaseUrl(
    MORE_DETAILS_LINKED_ACCOUNT_PATH
);
const MORE_DETAILS_LINKED_NEXT_STEPS_ACCOUNT_PATH = `/account/more-details/linked-account/next-steps`;
const MORE_DETAILS_LINKED_NEXT_STEPS_ACCOUNT_URL = prependAccountBaseUrl(
    MORE_DETAILS_LINKED_NEXT_STEPS_ACCOUNT_PATH
);
const MORE_DETAILS_URL = prependAccountBaseUrl(MORE_DETAILS_PATH);
const PROFILE_PICKER_URL = prependAccountBaseUrl('/account/profile-picker');
const REAUTHENTICATE_URL = prependAccountBaseUrl('/signin/reauthenticate');
const REGISTER_PATH = '/register';
const REGISTER_OTSI_PATH = '/register/otsi';
const REGISTER_FEDERATED_PATH = '/register/federated';
const REGISTER_FEDERATED_OTSI_PATH = '/register/federated/otsi';
const REGISTER_URL = prependAccountBaseUrl(REGISTER_PATH);
const REGISTER_FEDERATED_URL = prependAccountBaseUrl(REGISTER_FEDERATED_PATH);
const REGISTER_THANKS_PATH = '/register/thanks';
const REGISTER_FEDERATED_THANKS_PATH = '/register/federated/thanks';
const REGISTER_FEDERATED_THANKS_WITHOUT_MARKETING_PATH =
    '/register/federated/thanks/without-marketing';
const REGISTER_BLOCKED_PATH = '/register/sorry';
const REGISTER_FEDERATED_BLOCKED_PATH = '/register/federated/sorry';
const REGISTER_PROFILE_PATH = '/register/details/profile';
const REGISTER_PROFILE_THANKS_URL = prependAccountBaseUrl(
    '/register/thanks/profile'
);
const REGISTER_CHILD_PATH = '/register/details/children';
const REGISTER_CHILD_DOB_PATH = '/register/details/children/age';
const REGISTER_CHILD_EMAIL_INVALID_PATH = '/register/details/children/invalid';
const REGISTER_CHILD_GUARDIAN_PATH = '/register/details/guardian';
const REGISTER_CHILD_GUARDIAN_FEDERATED_PATH =
    '/register/federated/details/guardian';
const REGISTER_CHILD_GUARDIAN_INVALID_PATH =
    '/register/details/children/invalid-guardian';
const REGISTER_CHILD_GUARDIAN_THANKS_PATH = '/register/thanks/guardian';
const REGISTER_FEDERATED_CHILD_GUARDIAN_THANKS_PATH =
    '/register/federated/thanks/guardian';
const REGISTER_CHILD_MORE_DETAILS_PATH = `/register/details/children/more-details`;
const REGISTER_CHILD_PERMISSIONS_PATH =
    '/register/details/children/permissions';
const REGISTER_CHILD_THANKS_PATH = '/register/thanks/children';
const REGISTER_CHILD_THANKS_PARENT_LINKED_PATH =
    '/register/thanks/children/linked';
const REGISTER_FEDERATED_THANKS_URL = prependAccountBaseUrl(
    '/register/federated/thanks'
);
const REGISTER_DETAILS_PATH = '/register/details';
const REGISTER_FEDERATED_DETAILS_PATH = '/register/federated/details';
const REGISTER_DOB_PATH = '/register/details/age';
const REGISTER_FEDERATED_DOB_PATH = '/register/federated/details/age';
const RESET_PASSWORD_THANKS_PATH = '/signin/verify/password/thanks';
const RESET_PASSWORD_SORRY_PATH = '/signin/verify/password/sorry';
const RESET_PASSWORD_URL = prependAccountBaseUrl('/signin/verify/password');
const SIGN_IN_PATH = '/signin';
const SIGN_IN_OTSI_PATH = '/signin/otsi';
const SIGN_IN_URL = prependAccountBaseUrl(SIGN_IN_PATH);
const SIGN_IN_FEDERATED_PATH = '/signin/federated';
const SIGN_IN_FEDERATED_URL = prependAccountBaseUrl(SIGN_IN_FEDERATED_PATH);
const SIGN_IN_FEDERATED_LANDING_PATH = '/signin/federated/landing';
const SIGN_IN_FEDERATED_COMPLETE_URL = prependAccountBaseUrl(
    '/signin/federated/complete'
);
const SIGN_IN_FEDERATED_OTSI_PATH = '/signin/federated/otsi';
const SIGN_IN_LOCKED_EMAIL_URL = prependAccountBaseUrl('/signin/locked/email');
const SIGN_IN_LOCKED_FEDERATED_EMAIL_URL = prependAccountBaseUrl(
    '/signin/federated/locked/email'
);
const SIGN_IN_LOCKED_URL = prependAccountBaseUrl('/signin/locked');
const SIGN_IN_LOCKED_FEDERATED_URL = prependAccountBaseUrl(
    '/signin/federated/locked'
);
const SIGN_IN_HELP_PATH = '/signin/help';
const SIGN_IN_IDENTITY_PATH = '/signin/identity';
const SIGN_IN_IDENTITY_URL = prependAccountBaseUrl('/signin/identity');
const SIGN_IN_FEDERATED_HELP_PATH = '/signin/federated/help';
const TRACK_CLIENT_EVENT_PATH = '/register/track-event';
const VALIDATE_DISPLAY_NAME_PATH = '/register/details/validate/displayName';
const VALIDATE_HOMETOWN_PATH = '/register/details/validate/hometown';
const VALIDATE_POSTCODE_PATH = '/register/details/validate/postcode';
const VALIDATE_USERNAME_PATH = '/register/details/validate/username';
const VERIFY_EMAIL_COMPLETE_URL = prependAccountBaseUrl(
    '/signin/verify/email/complete'
);
const VERIFY_EMAIL_ISSUE_PATH = '/signin/verify/email/sorry';
const VERIFY_EMAIL_ISSUE_URL = prependAccountBaseUrl(VERIFY_EMAIL_ISSUE_PATH);

const VERIFY_EMAIL_VERIFIED_PATH = '/signin/verify/email/already-verified';
const VERIFY_EMAIL_VERIFIED_URL = prependAccountBaseUrl(
    VERIFY_EMAIL_VERIFIED_PATH
);

const FORGOT_PASSWORD_IFRAME_SIGNIN = prependAccountBaseUrl(
    FORGOTTEN_PASSWORD_PATH
);

const CREATE_PASSWORD_IFRAME = prependAccountBaseUrl(CREATE_PASSWORD_PATH);

export {
    AUTH_PASSWORD_PATH,
    AUTH_PATH,
    AUTH_URL,
    AUTH_MAGIC_LINK_AUTHENTICATE,
    AUTH_MAGIC_LINK_AUTHENTICATE_API_PATH,
    AUTH_MAGIC_LINK_AUTHENTICATE_SUCCESS_URL,
    AUTH_MAGIC_LINK_AUTHENTICATE_ERROR_URL,
    AUTH_MAGIC_LINK_PASSWORD_SUCCESS_URL,
    AUTH_MAGIC_LINK_VALIDATE_PATH,
    AUTH_MAGIC_LINK_VALIDATE_CANCEL_PATH,
    AUTH_MAGIC_LINK_VALIDATE_CLIENT_PATH,
    AUTH_MAGIC_LINK_VALIDATE_CHECKED_URL,
    AUTH_MAGIC_LINK_VALIDATE_CLIENT_SUCCESS_URL,
    AUTH_MAGIC_LINK_VALIDATE_ERROR_URL,
    AUTH_MAGIC_LINK_VALIDATE_SUCCESS_URL,
    CHILD_PERMISSIONS_AGE_CONFIRMATION_PATH,
    CHILD_PERMISSIONS_EMAIL_EXPIRED_PATH,
    CHILD_PERMISSIONS_EMAIL_INVALID_PATH,
    CHILD_PERMISSIONS_EMAIL_SENT_PATH,
    CHILD_PERMISSIONS_INCORRECT_DOB_PATH,
    CHILD_PERMISSIONS_THANKS_PATH,
    CHILD_PERMISSIONS_MANAGEMENT_PATH,
    CREATE_PASSWORD_IFRAME,
    CREATE_PASSWORD_PATH,
    CREATE_PASSWORD_VERIFY_PATH,
    FORGOTTEN_PASSWORD_PATH,
    FORGOTTEN_PASSWORD_URL,
    FORGOTTEN_PASSWORD_FEDERATED_PATH,
    FORGOTTEN_PASSWORD_FEDERATED_URL,
    FORGOTTEN_PASSWORD_VERIFY_PATH,
    FORGOTTEN_PASSWORD_VERIFY_FEDERATED_PATH,
    FORGOT_PASSWORD_IFRAME_SIGNIN,
    FORGOTTEN_CREDENTIALS_PATH,
    FORGOTTEN_CREDENTIALS_FEDERATED_PATH,
    HELP_FEDERATED_DOB_URL,
    HELP_FEDERATED_PROBLEMS_WITH_MY_EMAIL_ADDRESS_PATH,
    HELP_FEDERATED_PROBLEMS_WITH_MY_USERNAME_PATH,
    HELP_FEDERATED_PROBLEMS_WITH_MY_PASSWORD_PATH,
    HELP_FEDERATED_TERMS_AND_CONDITIONS_PATH,
    HELP_FEDERATED_PRIVACY_PATH,
    HELP_FEDERATED_WHAT_EMAILS_AND_NEWSLETTERS,
    HELP_FEDERATED_PASSWORD_MOBILE_DEVICES_PATH,
    MAINTENANCE_PATH,
    MORE_DETAILS_PATH,
    MORE_DETAILS_NEXT_STEPS_PATH,
    MORE_DETAILS_NEXT_STEPS_URL,
    MORE_DETAILS_LINKED_ACCOUNT_PATH,
    MORE_DETAILS_LINKED_ACCOUNT_URL,
    MORE_DETAILS_LINKED_NEXT_STEPS_ACCOUNT_PATH,
    MORE_DETAILS_LINKED_NEXT_STEPS_ACCOUNT_URL,
    MORE_DETAILS_URL,
    PROFILE_PICKER_URL,
    REAUTHENTICATE_URL,
    REGISTER_PATH,
    REGISTER_OTSI_PATH,
    REGISTER_FEDERATED_PATH,
    REGISTER_FEDERATED_OTSI_PATH,
    REGISTER_URL,
    REGISTER_FEDERATED_URL,
    REGISTER_THANKS_PATH,
    REGISTER_FEDERATED_THANKS_PATH,
    REGISTER_FEDERATED_THANKS_WITHOUT_MARKETING_PATH,
    REGISTER_BLOCKED_PATH,
    REGISTER_FEDERATED_BLOCKED_PATH,
    REGISTER_PROFILE_PATH,
    REGISTER_PROFILE_THANKS_URL,
    REGISTER_CHILD_PATH,
    REGISTER_CHILD_DOB_PATH,
    REGISTER_CHILD_EMAIL_INVALID_PATH,
    REGISTER_CHILD_GUARDIAN_PATH,
    REGISTER_CHILD_GUARDIAN_FEDERATED_PATH,
    REGISTER_CHILD_GUARDIAN_INVALID_PATH,
    REGISTER_CHILD_GUARDIAN_THANKS_PATH,
    REGISTER_FEDERATED_CHILD_GUARDIAN_THANKS_PATH,
    REGISTER_CHILD_MORE_DETAILS_PATH,
    REGISTER_CHILD_PERMISSIONS_PATH,
    REGISTER_CHILD_THANKS_PATH,
    REGISTER_CHILD_THANKS_PARENT_LINKED_PATH,
    REGISTER_FEDERATED_THANKS_URL,
    REGISTER_DETAILS_PATH,
    REGISTER_FEDERATED_DETAILS_PATH,
    REGISTER_DOB_PATH,
    REGISTER_FEDERATED_DOB_PATH,
    RESET_PASSWORD_THANKS_PATH,
    RESET_PASSWORD_SORRY_PATH,
    RESET_PASSWORD_URL,
    SIGN_IN_PATH,
    SIGN_IN_OTSI_PATH,
    SIGN_IN_URL,
    SIGN_IN_FEDERATED_PATH,
    SIGN_IN_FEDERATED_URL,
    SIGN_IN_FEDERATED_LANDING_PATH,
    SIGN_IN_FEDERATED_COMPLETE_URL,
    SIGN_IN_FEDERATED_OTSI_PATH,
    SIGN_IN_LOCKED_EMAIL_URL,
    SIGN_IN_LOCKED_FEDERATED_EMAIL_URL,
    SIGN_IN_LOCKED_URL,
    SIGN_IN_LOCKED_FEDERATED_URL,
    SIGN_IN_HELP_PATH,
    SIGN_IN_IDENTITY_PATH,
    SIGN_IN_IDENTITY_URL,
    SIGN_IN_FEDERATED_HELP_PATH,
    SINGLE_ENTRY_AUTH_PATH,
    TRACK_CLIENT_EVENT_PATH,
    VALIDATE_DISPLAY_NAME_PATH,
    VALIDATE_HOMETOWN_PATH,
    VALIDATE_POSTCODE_PATH,
    VALIDATE_USERNAME_PATH,
    VERIFY_EMAIL_COMPLETE_URL,
    VERIFY_EMAIL_ISSUE_PATH,
    VERIFY_EMAIL_ISSUE_URL,
    VERIFY_EMAIL_VERIFIED_PATH,
    VERIFY_EMAIL_VERIFIED_URL,
};
