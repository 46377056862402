import React from 'react';
import { CallToAction, CallToActionButton } from '@bbc-account/id-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import renderQueryString from '../../../shared/urls/renderQueryString';
import { IGNORE_VIEWS } from '../../../shared/data/analytics';
import withEventTracking from '../withEventTracking';
import connectToState from '../connectToState';

const matchPathRegex = /^(?:https?:\/\/)?([^\\/]+)?(\/[^\\?]*)/;

const NavigationLinkContainer = ({
    asButton = false,
    ariaLabel,
    children,
    className,
    createEventAttributes,
    fullWidth,
    handleOnClick,
    href,
    id,
    userContext,
    variant = 'secondary',
    withoutState,
}) => {
    const statefulHref = withoutState
        ? href
        : `${href}${renderQueryString.call(userContext)}`;

    const parsedPath = statefulHref.match(matchPathRegex)[2];

    const eventAttributes =
        typeof createEventAttributes === 'function'
            ? createEventAttributes({
                  type: 'link',
                  result: parsedPath,
                  ignoreViews: IGNORE_VIEWS,
              })
            : {};

    const ComponentWrapper = asButton ? CallToActionButton : CallToAction;

    let componentProps = {
        ariaLabel,
        variant,
        id,
        eventAttributes,
        className,
    };

    if (asButton) {
        componentProps = {
            ...componentProps,
            hideUnderlineOnHover: fullWidth,
            isFullWidth: fullWidth,
        };
    }

    return (
        <Link
            to={statefulHref}
            component={({ href: toHref, navigate, ...toProps }) => (
                <ComponentWrapper
                    onClick={() => {
                        if (typeof handleOnClick === 'function') {
                            handleOnClick(navigate, toHref);
                        } else {
                            navigate(toHref);
                        }
                    }}
                    {...toProps}
                    {...componentProps}
                >
                    {children}
                </ComponentWrapper>
            )}
        />
    );
};

NavigationLinkContainer.propTypes = {
    asButton: PropTypes.bool,
    ariaLabel: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    createEventAttributes: PropTypes.func,
    fullWidth: PropTypes.bool,
    handleOnClick: PropTypes.func,
    href: PropTypes.string.isRequired,
    id: PropTypes.string,
    userContext: PropTypes.shape({
        isNativeMobileApplication: PropTypes.bool,
        layout: PropTypes.string,
    }),
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    withoutState: PropTypes.bool,
};

export { NavigationLinkContainer };
export default connectToState(withEventTracking(NavigationLinkContainer));
