import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@bbc-account/id-formaxe';
import { EmailFirstForm } from '../emailFirstForm';
import { useEmailFirstForm } from '../useEmailFirstForm';
import { getEmailFirstRoutes } from '../../../../pages/email-first/emailFirstRouteNames';
import { useStateContext } from '../../../../modules/stateContext';
import {
    EMAIL_FIRST_VERIFICATION_FORM_INPUT_NAME,
    EmailFirstVerificationFormInput,
} from '../inputs/otp';
import { EMAIL_FIRST_EMAIL_FORM_INPUT_NAME } from '../inputs';
import Text from '../../../shared/text';
import NavigationLinkContainer from '../../../shared/navigationLinkContainer';
import { generateOtp, verifyOtp } from '../../../../services/register';
import renderQueryString from '../../../../../shared/urls/renderQueryString';

const UNEXPECTED_ERROR = 'message.gracefulDeg.technicalProblems';

const activeFields = [EMAIL_FIRST_VERIFICATION_FORM_INPUT_NAME];

export const ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY =
    'oneTimePersistentUserIdentifierKey';

export const EmailFirstVerificationForm = () => {
    const [formErrors, setFormErrors] = useState();

    const userContext = useStateContext();
    const { isFederated, isSingleEntryAuth } = userContext;
    const params = renderQueryString.call(userContext);

    const { emailFirstPaths } = getEmailFirstRoutes(
        isFederated,
        isSingleEntryAuth
    );
    const { setFieldValue, setFieldErrors } = useFormContext();

    const emailFormPath = emailFirstPaths.email.path;

    const preSubmit = useCallback(async ({ fieldValues }) => {
        setFormErrors(undefined);

        try {
            const response = await verifyOtp(
                fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME],
                fieldValues[EMAIL_FIRST_VERIFICATION_FORM_INPUT_NAME],
                fieldValues.otpJwt,
                params
            );

            if (!response.success) {
                if (response.jwt) {
                    setFieldValue('otpJwt', response.jwt);
                }

                if (
                    response.message === 'message.gracefulDeg.technicalProblems'
                ) {
                    setFormErrors(response.message);
                } else {
                    setFieldErrors(EMAIL_FIRST_VERIFICATION_FORM_INPUT_NAME, {
                        id: response.message,
                        values: {
                            b: content => (
                                <NavigationLinkContainer
                                    href={emailFormPath}
                                    className="link__form-error"
                                >
                                    {content}
                                </NavigationLinkContainer>
                            ),
                        },
                    });
                }

                return false;
            }
        } catch (error) {
            setFormErrors(UNEXPECTED_ERROR);

            return false;
        }

        return true;
    }, []);

    const {
        fieldValues,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        nextRoute: emailFirstPaths.password.path,
        prevRoute: emailFirstPaths.email.path,
        preSubmit,
    });

    const email = fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME];

    useEffect(() => {
        const fetchOtpPassword = async () => {
            try {
                const response = await generateOtp(email, params);

                if (!response.success) {
                    setFormErrors(response.message);
                }

                setFieldValue(EMAIL_FIRST_VERIFICATION_FORM_INPUT_NAME, '');
                setFieldErrors(EMAIL_FIRST_VERIFICATION_FORM_INPUT_NAME, null);
                setFieldValue('otpJwt', response.token);
            } catch (error) {
                setFormErrors('message.gracefulDeg.technicalProblems');
            }
        };

        fetchOtpPassword();
    }, []);

    return (
        <EmailFirstForm
            activeFields={activeFields}
            formError={formErrors}
            heading={<FormattedMessage id="emailFirst.verification.title" />}
            name="email-first-verification-form"
            hideBackButton
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
            pageId="registration-email-first"
            submitButtonLabel="button.continue.value"
            withEmailVerificationCta
        >
            <Text>
                <FormattedMessage
                    id="emailFirst.verification.emailConfirmation"
                    values={{
                        email:
                            fieldValues &&
                            fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME],
                        b: content => <span className="u-bold">{content}</span>,
                    }}
                />
            </Text>

            <Text>
                <FormattedMessage id="emailFirst.verification.validForTime" />
            </Text>

            <Text className="u-bold">
                <FormattedMessage id="emailFirst.verification.enterCode" />
            </Text>

            <EmailFirstVerificationFormInput
                defaultValue={
                    fieldValues &&
                    fieldValues[EMAIL_FIRST_VERIFICATION_FORM_INPUT_NAME]
                }
            />
        </EmailFirstForm>
    );
};
