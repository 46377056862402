import React from 'react';
import PropTypes from 'prop-types';
import { StyledInstruction, StyledInstructionIcon } from './instruction.style';

const Instruction = ({ number, children }) => {
    return (
        <StyledInstruction>
            <StyledInstructionIcon>{number}</StyledInstructionIcon> {children}
        </StyledInstruction>
    );
};

Instruction.propTypes = {
    /**
     * Position of the instruction in the instruction list
     */
    number: PropTypes.number.isRequired,
    /**
     * Text to display for the instruction
     */
    children: PropTypes.string.isRequired,
};

export { Instruction };
