import { Button } from '@bbc-account/id-components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useStateContext } from '../../../../modules/stateContext';
import Track from '../../../shared/analytics/track';
import { resolvePageForEvent } from '../../../../../shared/utilities/events';

export function AuthSubmitButton({
    children,
    isFullWidth,
    onClick,
    variant,
    pageOverride,
}) {
    const { signIn } = useStateContext();

    const page = signIn?.page || pageOverride;

    const resolvedPage = useMemo(() => resolvePageForEvent(page), [page]);

    return (
        <Track container={resolvedPage} ignoreViews name="submit">
            <Button
                id="submit-button"
                isFullWidth={isFullWidth}
                isSubmit
                variant={variant}
                onClick={onClick}
            >
                {children}
            </Button>
        </Track>
    );
}

AuthSubmitButton.propTypes = {
    children: PropTypes.node,
    isFullWidth: PropTypes.bool,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    pageOverride: PropTypes.string,
};
