import React, { Children, useCallback, useMemo, useState } from 'react';
import createFragment from 'react-addons-create-fragment';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ButtonInline } from '../buttonInline/buttonInline';
import { StyledValidIcon } from './textInput.style';

import './textInput.scss';

const TextInput = ({
    // DOM element props
    'aria-describedby': ariaDescribedBy,
    'aria-label': ariaLabel,
    autoComplete,
    id,
    label,
    name,
    type,
    value,
    placeholder,
    disabled,
    // custom props for styling and behaviour
    as: Input = 'input',
    errorComponent,
    message: messageProp,
    eventHandlers,
    fieldIcon,
    children,
    minimal,
    hasShowPasswordToggle,
    isInvalid: isInvalidProp,
    isValid: isValidProp,
    options,
}) => {
    const [isPasswordType, setIsPasswordType] = useState(() => {
        if (!minimal) return false;
        return type === 'password';
    });

    const togglePasswordType = useCallback((mouseEvent) => {
        mouseEvent.preventDefault();
        setIsPasswordType((current) => !current);
    }, []);

    const inputId = id || `${name}-input`;

    const isSelect = Input === 'select';

    const isInvalid =
        isInvalidProp ||
        (typeof errorComponent !== 'undefined' && !!errorComponent);

    const isValid = !isInvalid && isValidProp;

    const message = messageProp || errorComponent;

    const ariaInvalid = !isInvalid ? 'false' : 'true';

    const inputProps = {
        'aria-describedby': ariaDescribedBy,
        'aria-invalid': ariaInvalid,
        'aria-label': ariaLabel,
        'aria-required': true,
        autoCapitalize: 'none',
        autoComplete,
        autoCorrect: 'off',
        className: classNames('sb-field__input', {
            'sb-field__input--dropdown': isSelect,
            'sb-field__input--password-toggle': hasShowPasswordToggle,
        }),
        'data-testid': 'input',
        disabled,
        id,
        name,
        placeholder,
        required: true,
        type,
        value,
        ...eventHandlers,
    };

    if (minimal && type === 'password') {
        inputProps.type = isPasswordType ? 'password' : 'text';
    }

    const isPositionEnd = !fieldIcon || fieldIcon?.position === 'end';

    const isPositionStart = fieldIcon?.position === 'start';

    const isValidIcon = useMemo(
        () =>
            isValid && (
                <StyledValidIcon
                    className="sb-text-input__valid-icon"
                    data-testid="valid-icon"
                />
            ),
        [isValid]
    );

    /**
     * TODO the current implementation of the TextInput icons doesn't show the valid icon
     * when the icon position is set to "start". It is recommended that the component
     * should be refactored to implement flex box better and allow icons to co-exist both
     * at the start and end of the grid.
     *
     * Example grid:
     * |----------------|---------------|--------------|
     * | start icon set | input element | end icon set |
     * |----------------|---------------|--------------|
     *
     * Where each icon set is optional, the input element can be decorated while
     * maintaining the valid icon is always in the end set.
     */

    const icons = useMemo(() => {
        const iconsSet = {};

        if (fieldIcon?.node) {
            iconsSet.fieldIcon = fieldIcon.node;
        }

        if (isPositionEnd && isValidIcon) {
            iconsSet.isValidIcon = isValidIcon;
        }

        const iconChildren = createFragment(iconsSet);

        return (
            Children.count(iconChildren) > 0 && (
                <div
                    className={classNames('sb-text-input__icon-set', {
                        'sb-text-input__icon-set--position-start':
                            isPositionStart,
                    })}
                >
                    {iconChildren}
                </div>
            )
        );
    }, [fieldIcon, isPositionEnd, isPositionStart, isValidIcon]);

    return (
        <div
            className={classNames('sb-field', {
                'sb-field--populated': !!value,
                'sb-field--invalid': isInvalid,
                'sb-field--valid': isValid,
                'sb-field-minimal': minimal,
            })}
        >
            {label && minimal && (
                <label
                    htmlFor={inputId}
                    className="sb-field-minimal__label"
                    id={`${name}-label`}
                >
                    {label}
                </label>
            )}

            <div
                className={classNames(
                    'sb-field__input-container',
                    {
                        'sb-field__input-container--dropdown': isSelect,
                    },
                    fieldIcon?.position || 'end'
                )}
                data-testid="input-container"
            >
                {icons}

                {isSelect ? (
                    <Input {...inputProps}>
                        {options?.map(
                            ({
                                disabled: optionDisabled,
                                label: optionLabel,
                                value: optionValue,
                            }) => (
                                <option
                                    disabled={optionDisabled}
                                    key={`${optionLabel}: ${optionValue}`}
                                    value={optionValue}
                                >
                                    {optionLabel}
                                </option>
                            )
                        )}
                    </Input>
                ) : (
                    <Input {...inputProps} />
                )}

                {minimal
                    ? type === 'password' &&
                      hasShowPasswordToggle && (
                          <ButtonInline
                              data-testid="show-password-toggle"
                              id="sb-text-input-minimal-toggle-password-view"
                              variant="secondary"
                              onClick={togglePasswordType}
                          >
                              {isPasswordType
                                  ? 'Show password'
                                  : 'Hide password'}
                          </ButtonInline>
                      )
                    : label && (
                          <label
                              htmlFor={inputId}
                              className="sb-field__label"
                              id={`${name}-label`}
                          >
                              {label}
                          </label>
                      )}
                {children}
            </div>
            {!minimal && (
                <div
                    className="sb-field__decoration"
                    data-testid="decoration"
                />
            )}

            {!!message && (
                <div className="sb-field__message-container">{message}</div>
            )}
        </div>
    );
};

TextInput.propTypes = {
    /**
     * Identifies the element (or elements) that describes the element on which the attribute is set.
     */
    'aria-describedby': PropTypes.string,

    /**
     * Defines a string value that can be used to name an element
     */
    'aria-label': PropTypes.string,

    /**
     * The autoComplete property passed to the text input
     */
    autoComplete: PropTypes.string,

    /**
     * The ID of the input element
     */
    id: PropTypes.string,

    /**
     * The text label
     */
    label: PropTypes.node,

    /**
     * The field name of the element, as will be submited in the form data
     */
    name: PropTypes.string.isRequired,

    /**
     * The type property passed to the text input
     */
    type: PropTypes.oneOf(['email', 'password', 'text', 'username']),

    /**
     * The element's current value
     */
    value: PropTypes.string,

    /**
     * The element's placeholder attribute value
     */
    placeholder: PropTypes.string,

    /**
     * The element's disabled attribute value
     */
    disabled: PropTypes.bool,

    /**
     * The validation error component
     *
     * @deprecated
     * This legacy property remains for backwards compatibility.
     * Use `message` and `isInvalid` property instead.
     *
     * Example:
     * ```
     * <TextInput
     *   isInvalid
     *   message={
     *     <Message isError>Error message</Message>
     *   }
     * />
     * ```
     */
    errorComponent: PropTypes.node,

    /**
     * Invalid field indicator
     */
    isInvalid: PropTypes.bool,

    /**
     * The message component
     */
    message: PropTypes.node,

    /**
     * An object of event handlers that will be attached to the form element
     */
    eventHandlers: PropTypes.shape({
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
    }),

    /**
     * A jsx element to position inside the input field, at start or at the end
     * eg. icon, icon-button etc.
     */
    fieldIcon: PropTypes.shape({
        node: PropTypes.node,
        position: PropTypes.oneOf(['start', 'end']),
    }),

    /**
     * An optional flag, whether a new, `minimal` version styling should be used
     */
    minimal: PropTypes.bool,

    /**
     * React child elements
     */
    children: PropTypes.node,

    /**
     * Toggles the class name for when there is a show password toggle
     */
    hasShowPasswordToggle: PropTypes.bool,

    /**
     * Flags the field as valid
     */
    isValid: PropTypes.bool,

    /**
     * Options for a select input
     */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            label: PropTypes.string,
            // eslint-disable-next-line react/forbid-prop-types
            value: PropTypes.any,
        })
    ),

    /**
     * The underlying HTML element
     */
    as: PropTypes.oneOfType([
        PropTypes.oneOf(['input', 'select', 'textarea']),
        PropTypes.elementType,
    ]),
};

TextInput.displayName = 'TextInput';

export { TextInput };
