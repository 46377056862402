import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormStateProvider, useFormState } from '@bbc-account/id-formaxe';
import { useStateContext } from '../../modules/stateContext';
import FormError from '../shared/formError';
import { AuthSubmitButton } from './buttons';

const AuthForm = ({
    children,
    action,
    buttonTranslationKey,
    'data-testid': dataTestId,
    onSubmit,
    pageOverride,
}) => {
    const { signIn } = useStateContext();

    const initialValues = signIn?.initialValues || {};
    const initialErrors = signIn?.initialErrors || {};

    const formState = useFormState({
        initialValues,
        initialErrors,
    });

    const showGeneralErrors = Object.keys(formState.fieldValues).every(
        key => formState.fieldValues[key] === initialValues[key]
    );

    return (
        <>
            <FormError
                error={showGeneralErrors && initialErrors.general}
                name="general"
                migrateToIdComponents
                isAssertive
            />
            <form
                method="post"
                action={action}
                noValidate
                data-testid={dataTestId}
                onSubmit={onSubmit}
            >
                <FormStateProvider value={formState}>
                    {children}
                </FormStateProvider>
                <AuthSubmitButton isFullWidth pageOverride={pageOverride}>
                    <FormattedMessage id={buttonTranslationKey} />
                </AuthSubmitButton>
            </form>
        </>
    );
};

AuthForm.displayName = 'AuthForm';

AuthForm.propTypes = {
    action: PropTypes.string,
    children: PropTypes.node,
    'data-testid': PropTypes.string,
    buttonTranslationKey: PropTypes.string,
    onSubmit: PropTypes.func,
    pageOverride: PropTypes.string,
};

AuthForm.defaultProps = {
    buttonTranslationKey: 'signIn.button.submit.value',
    'data-testid': 'form',
};

export default AuthForm;
