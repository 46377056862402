import { sendCustomEvent } from '../../../../shared/events/sendCustomEvent';

type SendCustomEventOptions = Parameters<typeof sendCustomEvent>[0];

type SendCustomEventHttpClient = SendCustomEventOptions['httpClient'];

type SendCustomEventPostFunction = SendCustomEventHttpClient['post'];

type SendCustomEventPostFunctionOptions = Parameters<
    SendCustomEventPostFunction
>[0];

type SendCustomEventPostFunctionBody = SendCustomEventPostFunctionOptions['body'];

type FetchParameters = Parameters<typeof fetch>;

type FetchInput = FetchParameters[0];

type FetchInit = Exclude<FetchParameters[1], undefined>;

type FetchHeaders = FetchInit['headers'];

type PostCustomEventOptions = {
    body: SendCustomEventPostFunctionBody;
    headers?: FetchHeaders;
    url: FetchInput;
};

export async function postCustomEvent({
    body,
    headers,
    url,
}: PostCustomEventOptions) {
    await fetch(url, {
        body: JSON.stringify(body),
        method: 'POST',
        headers,
    });
}
