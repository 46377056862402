const ABSOLUTE_URL_REGEX = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/;

export function getUserOriginFromPtrt(ptrt?: string) {
    if (!ptrt || !ABSOLUTE_URL_REGEX.test(ptrt)) {
        return 'Account';
    }

    const parsedPtrt = new URL(ptrt);

    const { pathname } = parsedPtrt;

    const [userOrigFromPtrt] = pathname.split('/').filter(Boolean);

    if (
        ['auth', 'signin', 'register', 'account', undefined].includes(
            userOrigFromPtrt
        )
    ) {
        return 'Account';
    }

    return userOrigFromPtrt;
}
