import React from 'react';
import Text from '../../../shared/text';

const FunctionalLongVariant = () => (
    <>
        <Text>We need to know the age of the person creating the account.</Text>
        <Text>
            That’s because you need to be over 13 to register without a parent
            or guardian’s permission. It also helps us show the right content.
        </Text>
    </>
);

export default FunctionalLongVariant;
