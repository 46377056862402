import React from 'react';
import PropTypes from 'prop-types';
import { InstructionContainer } from '../instructionContainer/instructionContainer';
import { Instruction } from './instruction/instruction';

const InstructionList = ({ items, title }) => (
    <InstructionContainer>
        {title}
        <ol>
            {items.map((element, index) => {
                return (
                    <Instruction number={index + 1} key={index}>
                        {element}
                    </Instruction>
                );
            })}
        </ol>
    </InstructionContainer>
);

InstructionList.propTypes = {
    /**
     * The list of instructions to display
     */
    items: PropTypes.arrayOf(Instruction.propTypes.children),
    /**
     * Title for the instructions
     */
    title: PropTypes.node.isRequired,
};

InstructionList.displayName = 'InstructionList';

export { InstructionList };
