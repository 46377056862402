export type CustomEvent = {
    data: Record<string, unknown>;
    name: string;
};

type SendCustomEventPostFunctionHeaders = Record<string, string>;

type SendCustomEventPostFunctionBody = {
    events: CustomEvent[];
};

type SendCustomEventPostFunctionOptions = {
    body: SendCustomEventPostFunctionBody;
    headers?: SendCustomEventPostFunctionHeaders;
    url: string;
};

type SendCustomEventPostFunction = (
    options: SendCustomEventPostFunctionOptions
) => Promise<void>;

type SendCustomEventHttpClient = {
    post: SendCustomEventPostFunction;
};

type SendCustomEventOptions = {
    accountId: string;
    baseUrl: string;
    event: CustomEvent;
    headers?: SendCustomEventPostFunctionOptions['headers'];
    httpClient: SendCustomEventHttpClient;
};

export async function sendCustomEvent({
    accountId,
    baseUrl,
    event,
    headers,
    httpClient,
}: SendCustomEventOptions) {
    await httpClient.post({
        body: {
            events: [event],
        },
        headers,
        url: `${baseUrl}?s=${accountId}`,
    });

    return event.name;
}
