const ACCOUNT_CLIENT = 'Account';
const IPLAYER_TV_CLIENT = 'iPlayerTV';

const FEDERATED_CLIENTS = {
    amazonAlexa: {
        service: 'Alexa',
        privacyUrl: 'amazon.co.uk',
        allowU13s: false,
    },
    amazonAlexaNews: {
        service: 'Alexa',
        privacyUrl: 'amazon.co.uk',
        allowU13s: false,
    },
    cornmarket: {
        service: 'Cornmarket',
        privacyUrl: 'inrupt.com',
        allowU13s: false,
    },
    digame: {
        service: 'Junior Eurovision',
        privacyUrl: 'tally.tv/bbc_privacy',
        allowU13s: true,
        useNonFederatedU13Registration: true,
    },
    geniusWeb: {
        service: 'BBC Sport Game Hub',
        privacyUrl: '',
        allowU13s: false,
    },
    google: {
        service: 'Google',
        privacyUrl: 'google.co.uk',
        allowU13s: false,
    },
    sonos: {
        service: 'Sonos',
        privacyUrl: 'sonos.co.uk',
        allowU13s: false,
    },
    traitors: {
        service: 'Traitors',
        privacyUrl: '',
        allowU13s: false,
    },
    myBluePeter: {
        service: 'Blue Peter Badge',
        privacyUrl: '',
        allowU13s: true,
        useNonFederatedU13Registration: true,
    },
};

function getClient(clientName) {
    return FEDERATED_CLIENTS[clientName] || {};
}

function allowU13sByClient(clientId) {
    return FEDERATED_CLIENTS[clientId]
        ? FEDERATED_CLIENTS[clientId].allowU13s
        : true;
}

export { ACCOUNT_CLIENT, allowU13sByClient, getClient, IPLAYER_TV_CLIENT };
