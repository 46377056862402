import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CategoryIcon from '../../gel/iconography/icons';

import './button.scss';

const OptionalIcon = ({ icon, iconPosition = 'start', children }) => {
    if (!icon) return children;

    return iconPosition === 'start' ? (
        <span className="sb-button--icon-wrapper">
            <CategoryIcon className="sb-icon sb-icon--left" name={icon} />
            {children}
        </span>
    ) : (
        <span className="sb-button--icon-wrapper">
            {children}
            <CategoryIcon className="sb-icon sb-icon--right" name={icon} />
        </span>
    );
};

const Button = ({
    ariaLabel,
    children,
    'data-testid': dataTestId,
    isDisabled = false,
    isFullWidth = false,
    icon,
    iconPosition = 'start',
    isSubmit = false,
    formAction,
    variant = 'primary',
    id,
    onClick,
}) => {
    const classes = classNames('sb-button', {
        'sb-button--full-width': isFullWidth,
        'sb-button--secondary': variant === 'secondary',
        'sb-button--tertiary': variant === 'tertiary',
        'sb-button--focus-outlined': variant === 'focus-outlined',
    });

    return (
        <button
            data-testid={dataTestId}
            type={isSubmit ? 'submit' : 'button'}
            disabled={isDisabled}
            aria-label={ariaLabel}
            className={classes}
            id={id}
            onClick={onClick}
            formAction={formAction}
        >
            <OptionalIcon iconPosition={iconPosition} icon={icon}>
                {children}
            </OptionalIcon>
        </button>
    );
};

OptionalIcon.propTypes = {
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    children: PropTypes.node.isRequired,
};

Button.propTypes = {
    /**
     This attribute defines a string value that labels an interactive element.
    */
    ariaLabel: PropTypes.string,
    /**
     The content of the button
    */
    children: PropTypes.node.isRequired,
    /**
     * Test ID
     */
    'data-testid': PropTypes.string,
    /**
    Sets form-action attribute for a submit button if used within a form context
    */
    formAction: PropTypes.string,
    /**
     Determines whether the button is disabled (if it can be selected and clicked on). See the accessibility notes below if using on form submit buttons
     */
    isDisabled: PropTypes.bool,
    /**
     Style of button needed. Values are primary, secondary, 'focus-outlined'. Defaults to primary.
     */
    variant: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'focus-outlined',
    ]),
    /**
     Determines whether the button is the full width of its parent or the width of the button content. Values are content-length or full. Defaults to content-length. full will also centre the text content of the link
     */
    isFullWidth: PropTypes.bool,
    /**
     Determines what icon is shown on the button. If not passed then no icon will be shown.
     */
    icon: PropTypes.oneOf(['confirm', 'signed-in', 'sign-in', 'spinner']),
    /**
     Determines where the icon is shown on the button.
     */
    iconPosition: PropTypes.oneOf(['start', 'end']),
    /**
    Sets the button to the type "submit" if set to true, and "button" if false.
     */
    isSubmit: PropTypes.bool,
    /**
    Sets the button html ID to the provided string. If not provided, then it has a default value of undefined.
    */
    id: PropTypes.string,
    /**
    Sets an onClick handler function on the button. If not provided, then it has a default value of undefined.
    */
    onClick: PropTypes.func,
};

Button.displayName = 'Button';

export { Button };
